import { useDropzone } from 'react-dropzone';
import { AddAttachmentCnt, MainCnt } from './style';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import DIALOGS from 'utils/dialogIds';
import { v4 as uuidV4 } from 'uuid';
import useUpload from 'utils/CustomHooks/useUpload';

const MAX_UPLOAD_SIZE = 1024;

function UploadDocument(props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { uploadFile } = useUpload();
  const { updateHandler, manageLoader, children, isClose = true } = props;

  // Handle Upload Function with chunking
  const handleUpload = async (files) => {
    isClose &&
      dispatch(
        setDialog({
          open: false,
          dialogId: DIALOGS?.CREATE_DOCUMENT_DIALOG,
        }),
      );
    uploadFile(
      {
        uploadId: uuidV4(),
        file: files[0],
      },
      // this call back is after uploading done
      (response) => {
        const path = response?.filePath;
        const splited = path?.split('/');
        const title = splited[splited?.length - 1];
        updateHandler({
          path,
          title,
        });
      },
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: handleUpload,
    maxFiles: 1,
    multiple: false,
    maxSize: MAX_UPLOAD_SIZE * 1048576,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/vnd.ms-excel': ['xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        'xlsx',
      ],
      'text/csv': ['.csv'],
      'application/vnd.ms-powerpoint': ['.ppt'],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        ['.pptx'],
      'image/*': ['.jpeg', '.jpg', '.png', '.heic', '.webp'],
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/x-msvideo': ['.avi'],
    },
    onDropRejected: (rejectedFiles) => {
      const errorMessage = rejectedFiles?.[0]?.errors?.[0]?.code;
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    },
  });

  return (
    <MainCnt>
      <AddAttachmentCnt {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        {children}
      </AddAttachmentCnt>
    </MainCnt>
  );
}

export default UploadDocument;
