import { useContext, useMemo } from 'react';
import { Box, Divider } from '@mui/material';
import { AssigneeDropdownCnt } from 'components/AllDialogs/tasks/editTask/createTask.style';
import { CreateTaskContext } from 'components/AllDialogs/tasks/editTask/context/context';
import TaskAssignTo from 'components/TaskInputs/assignTo';
import TaskNotification from '../createTask/taskNotification';
import { PROJECT_TYPES } from 'utils/globalVariable';
import { cloneDeep, union } from 'lodash';

const TaskAssigneeDropdown = (props) => {
  const { hideElements, updateWatchers } = props;
  const { updateTask, newTask, project } = useContext(CreateTaskContext);

  const getNewOverdueWatchers = (assignees) => {
    const OldOverdueWatchers = cloneDeep(
      newTask?.notification?.overdue?.recipients || [],
    );

    const CurrentAssignees = cloneDeep(assignees || []);
    const finaldata = union(OldOverdueWatchers, CurrentAssignees);
    return finaldata || [];
  };

  const handleUpdateAssignee = (assignees) => {
    updateTask({
      assignees,
      notification: {
        ...newTask?.notification,
        overdue: {
          recipients: getNewOverdueWatchers(assignees),
        },
      },
    });
  };

  const handleUpdateRole = (assigneesRoles) => {
    updateTask({
      assignees: assigneesRoles?.roles ?? [],
    });
  };

  const selectedUsersAndTeams = useMemo(() => {
    if (!newTask?.TaskAssignees?.[0]?.Role) {
      return newTask?.assignees;
    }
    return [];
  }, [newTask?.TaskAssignees]);

  const selectedRole = useMemo(() => {
    if (newTask?.TaskAssignees?.[0]?.Role) {
      return {
        roles: newTask?.assignees,
        locations: newTask?.assigneesRoles?.locations ?? [],
        creationType: newTask?.assigneesRoles?.creationType ?? '',
      };
    }
    return { roles: [], locations: [] };
  }, [newTask?.TaskAssignees, newTask?.assigneesRoles]);

  return (
    <AssigneeDropdownCnt>
      <label>Assignee</label>

      {!hideElements?.watchers && (
        <Box className="watchersCnt">
          <TaskNotification
            updateWatchers={(watchers) =>
              updateWatchers(watchers, 'statusChange')
            }
            watchers={newTask?.notification?.statusChange?.recipients || []}
          />
        </Box>
      )}

      <Divider className="separator" />

      <TaskAssignTo
        handleChangeUsersAndTeamsCallback={handleUpdateAssignee}
        handleChangeRolesCallback={handleUpdateRole}
        selectedUsersAndTeams={selectedUsersAndTeams}
        selectedRole={selectedRole}
        readOnly={
          project?.type === PROJECT_TYPES.userBasedProject ||
          project?.type === PROJECT_TYPES.roleBasedProject ||
          (newTask?.parentTask?.id && newTask?.parentTask?.id === newTask?.id)
        }
        rolesProps={{
          hideLocations:
            project?.type === PROJECT_TYPES.roleBasedProject ? false : true,
          hideConfig:
            project?.type === PROJECT_TYPES.roleBasedProject ? false : true,
        }}
      />
    </AssigneeDropdownCnt>
  );
};

export default TaskAssigneeDropdown;
