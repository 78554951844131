import React, { FC, useState } from 'react';
import { Stack } from '@mui/material';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import CustomDropdown from 'components/Dropdown/CustomDropdown/CustomDropdown';
import LocationItemsContainer from 'components/Dropdown/LocationsSelectItemsList/LocationItemsContainer';
import useLocationsUtils from 'utils/CustomHooks/useLocationsUtils';
import { Content } from './style';

interface IProps {
  handleChangeCallback?: (
    currentSelected: string,
    allLocations: string[],
    parentLocationids: string[],
  ) => void;
  popperProps?: any;
  buttonRenderer?: any;
  buttonRendererProps?: any;
  rootProps?: any;
  data: any;
  selected: string[];
  selectedParents: string[];
}

const RolesLocationsAssigneeDropdown: FC<IProps> = (props) => {
  const {
    handleChangeCallback,
    popperProps = {},
    buttonRenderer,
    buttonRendererProps = {},
    rootProps = {},
    data = [],
    selected = [],
  } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleDropDownOpen = (event: React.MouseEvent<HTMLElement>) => {
    event?.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onDropdownClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const { filterParentLocationIds } = useLocationsUtils();

  const changeHandler = (currentSelected, selectedList) => {
    const parentLocationIds = filterParentLocationIds(selectedList, data);
    handleChangeCallback?.(currentSelected, selectedList, parentLocationIds);
  };

  return (
    <ClickAwayListener
      onClickAway={onDropdownClose}
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
    >
      <CustomDropdown
        closeAction={onDropdownClose}
        buttonRenderer={
          <div
            onClick={handleDropDownOpen}
            style={{ width: '100%' }}
            {...rootProps}
          >
            {buttonRenderer}
          </div>
        }
        buttonRendererProps={buttonRendererProps}
        clickaway={true}
        paperProps={{
          style: {
            boxShadow: '0 10px 15px 3px #00000033, 0 4px 6px -2px #00000033',
          },
        }}
        popperProps={{
          content: (
            <Stack {...(popperProps.contentRootProps || {})}>
              <Content>
                <LocationItemsContainer
                  data={data}
                  selected={selected}
                  changeHandler={changeHandler}
                  contentProps={{
                    isSearch: true,
                    isSelectAll: true,
                    isMulti: true,
                    style: {
                      maxHeight: 300,
                      overflow: 'auto',
                    },
                  }}
                />
              </Content>
            </Stack>
          ),
          disablePortal: true,
          placement: 'bottom-start',

          open: open,
          anchorEl: anchorEl,
          style: {
            zIndex: 1300,
            width: 415,
          },
          ...popperProps,
        }}
      />
    </ClickAwayListener>
  );
};

export default RolesLocationsAssigneeDropdown;
