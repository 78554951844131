import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { Stack, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  cloneDeep,
  difference,
  every,
  includes,
  mapValues,
  sortBy,
  union,
} from 'lodash';
import CustomCheckbox from 'components/Form/Checkbox/Checkbox';
import NoRecordsFound from 'components/ListView/EmptyRecords/noRecordsFound.cmp';
import CustomInput from 'components/Form/TextField/TextField.cmp';
import useCustomRoles from 'utils/CustomHooks/useCustomRoles';
import {
  PERMISSIONS,
  PERMISSIONS_ENTITY_KEYWORDS,
  TASKS_PERMISSIONS_ORDER,
  TEMPLATES_PERMISSIONS_ORDER,
} from 'utils/constants';
import { getPermissionsForRoles } from 'store/selectors/permissions';
import { QUERY_KEYS } from 'api/constants';
import InfoIcon from '@mui/icons-material/Info';
import {
  EmptyCnt,
  InfoBanner,
  MainPermission,
  PermissionsListingCnt,
  SearchBox,
  SubPermission,
} from './style';

type Permission = string | number;

interface CustomRolesSetPermissionsListingProps {
  selected: Permission[];
  onSelectedChange?: (selected: Permission[]) => void;
  isSearch?: boolean; // Added isSearch prop
  height?: any;
}

const CustomRolesSetPermissionsListing: React.FC<
  CustomRolesSetPermissionsListingProps
> = ({
  selected = [],
  height = 400,
  onSelectedChange,
  isSearch = true, // Default to true if not provided
}) => {
  const [selectedState, setSelectedState] = useState<Permission[]>(selected);
  const [searchedTerm, setSearchedTerm] = useState('');
  const [filteredPermissionsList, setFilteredPermissionsList] = useState<any>(
    {},
  );

  const permissions = useSelector(getPermissionsForRoles);
  const { list: permissionsList } = permissions || {};
  const { getPermissions } = useCustomRoles();

  useQuery(QUERY_KEYS.GET_PERMISSIONS, getPermissions);

  useEffect(() => {
    const clonedList = cloneDeep(permissionsList);

    setFilteredPermissionsList(clonedList ?? {}); // Initialize filtered list with all permissions
  }, [permissionsList]);

  useEffect(() => {
    setSelectedState(selected);
  }, [selected]);

  const parentChangeHandler = (
    parentKey: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;
    const updatedList = isChecked
      ? union(
          permissionsList[parentKey].map((item) => item.id),
          selectedState,
        )
      : difference(
          selectedState,
          permissionsList[parentKey].map((item) => item.id),
        );
    setSelectedState(updatedList);
    onSelectedChange?.(updatedList);
  };

  const updateTasksPermissionsState = (data) => {
    let clonedData = data;
    const CanAccessTasks = filteredPermissionsList?.['TASK']?.find(
      (item) => item?.title === PERMISSIONS.CAN_VIEW_TASKS,
    );

    const isCanAccessTasks = data?.includes(CanAccessTasks?.id);
    if (!isCanAccessTasks) {
      const CanOnlyViewOwnTasks = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
      );

      clonedData = clonedData?.filter((id) => id != CanOnlyViewOwnTasks?.id);

      const CanViewOwnAndOthersTasks = filteredPermissionsList?.['TASK']?.find(
        (item) =>
          item?.title === PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
      );

      clonedData = clonedData?.filter(
        (id) => id != CanViewOwnAndOthersTasks?.id,
      );

      const CanViewAllTasks = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
      );

      clonedData = clonedData?.filter((id) => id != CanViewAllTasks?.id);
    }

    const CanOnlyViewOwnTasks = filteredPermissionsList?.['TASK']?.find(
      (item) => item?.title === PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS,
    );

    const CanViewOwnAndOthersTasks = filteredPermissionsList?.['TASK']?.find(
      (item) =>
        item?.title === PERMISSIONS.CAN_VIEW_OTHERS_TASKS_AT_OWN_LOCATIONS,
    );

    const CanViewAllTasks = filteredPermissionsList?.['TASK']?.find(
      (item) => item?.title === PERMISSIONS.CAN_VIEW_OTHERS_TASKS,
    );

    const isNotExists =
      !data?.includes(CanOnlyViewOwnTasks?.id) &&
      !data?.includes(CanViewOwnAndOthersTasks?.id) &&
      !data?.includes(CanViewAllTasks?.id);

    if (isNotExists || !isCanAccessTasks) {
      const CanManageTasks = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === PERMISSIONS.CAN_MANAGE_TASKS,
      );

      clonedData = clonedData?.filter((id) => id != CanManageTasks?.id);

      const CanChangeTaskStatus = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === PERMISSIONS.CAN_CHANGE_TASK_STATUS,
      );

      clonedData = clonedData?.filter((id) => id != CanChangeTaskStatus?.id);

      const CanChangeOthersTaskStatus = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === PERMISSIONS.CAN_CHANGE_OTHERS_TASK_STATUS,
      );

      clonedData = clonedData?.filter(
        (id) => id != CanChangeOthersTaskStatus?.id,
      );

      const CanSelectAllLocations = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === PERMISSIONS.CAN_SELECT_ALL_LOCATIONS,
      );

      clonedData = clonedData?.filter((id) => id != CanSelectAllLocations?.id);

      const CanManageCategories = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === PERMISSIONS.CAN_MANAGE_CATEGORIES,
      );

      clonedData = clonedData?.filter((id) => id != CanManageCategories?.id);
    }

    return clonedData;
  };

  const updateTemplatesPermissionsState = (data) => {
    let clonedData = data;
    const CanAccessTemplates = filteredPermissionsList?.['CHECKLIST']?.find(
      (item) => item?.title === PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION,
    );

    const isCanAccessTemplates = data?.includes(CanAccessTemplates?.id);
    if (!isCanAccessTemplates) {
      const CanManageTemplates = filteredPermissionsList?.['CHECKLIST']?.find(
        (item) => item?.title === PERMISSIONS.CAN_MANAGE_CHECKLIST,
      );

      clonedData = clonedData?.filter((id) => id != CanManageTemplates?.id);

      const CanViewAllSubmissions = filteredPermissionsList?.[
        'CHECKLIST'
      ]?.find((item) => item?.title === PERMISSIONS.CAN_VIEW_ALL_SUBMISSIONS);

      clonedData = clonedData?.filter((id) => id != CanViewAllSubmissions?.id);

      const CanViewOwnSubmissions = filteredPermissionsList?.[
        'CHECKLIST'
      ]?.find((item) => item?.title === PERMISSIONS.CAN_VIEW_OWN_SUBMISSIONS);

      clonedData = clonedData?.filter((id) => id != CanViewOwnSubmissions?.id);

      const CanViewOwnAndOtherLocationsSubmissions = filteredPermissionsList?.[
        'CHECKLIST'
      ]?.find(
        (item) =>
          item?.title === PERMISSIONS.CAN_VIEW_OWN_AND_LOCATION_SUBMISSIONS,
      );

      clonedData = clonedData?.filter(
        (id) => id != CanViewOwnAndOtherLocationsSubmissions?.id,
      );
    }

    return clonedData;
  };

  const updateViewSubmissionsPermissions = (data, permission, isChecked) => {
    let clonedData = data;

    const canViewAllSubmissions = permissionsList?.['CHECKLIST']?.find(
      (item) => item?.title === PERMISSIONS.CAN_VIEW_ALL_SUBMISSIONS,
    );

    const CanViewOwnAndOtherLocationsSubmissions = permissionsList?.[
      'CHECKLIST'
    ]?.find(
      (item) =>
        item?.title === PERMISSIONS.CAN_VIEW_OWN_AND_LOCATION_SUBMISSIONS,
    );

    const CanViewOwnSubmissions = permissionsList?.['CHECKLIST']?.find(
      (item) => item?.title === PERMISSIONS.CAN_VIEW_OWN_SUBMISSIONS,
    );

    if (permission === canViewAllSubmissions?.id && isChecked) {
      clonedData = clonedData?.filter(
        (id) =>
          id != CanViewOwnAndOtherLocationsSubmissions?.id &&
          id != CanViewOwnSubmissions?.id,
      );
    }

    if (
      permission === CanViewOwnAndOtherLocationsSubmissions?.id &&
      isChecked
    ) {
      clonedData = clonedData?.filter(
        (id) =>
          id != canViewAllSubmissions?.id && id != CanViewOwnSubmissions?.id,
      );
    }

    if (permission === CanViewOwnSubmissions?.id && isChecked) {
      clonedData = clonedData?.filter(
        (id) =>
          id != canViewAllSubmissions?.id &&
          id != CanViewOwnAndOtherLocationsSubmissions?.id,
      );
    }

    return clonedData;
  };

  const NoActionApplyPermissions = (permission, isChecked) => {
    const canViewAllSubmissions = permissionsList?.['CHECKLIST']?.find(
      (item) => item?.title === PERMISSIONS.CAN_VIEW_ALL_SUBMISSIONS,
    );

    const CanViewOwnAndOtherLocationsSubmissions = permissionsList?.[
      'CHECKLIST'
    ]?.find(
      (item) =>
        item?.title === PERMISSIONS.CAN_VIEW_OWN_AND_LOCATION_SUBMISSIONS,
    );

    const CanViewOwnSubmissions = permissionsList?.['CHECKLIST']?.find(
      (item) => item?.title === PERMISSIONS.CAN_VIEW_OWN_SUBMISSIONS,
    );

    if (permission === canViewAllSubmissions?.id && !isChecked) {
      return true;
    }

    if (
      permission === CanViewOwnAndOtherLocationsSubmissions?.id &&
      !isChecked
    ) {
      return true;
    }

    if (permission === CanViewOwnSubmissions?.id && !isChecked) {
      return true;
    }

    return false;
  };

  const subChangeHandler = (
    permission: Permission,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const isChecked = event.target.checked;

    const isNoApplyAction = NoActionApplyPermissions(permission, isChecked);

    if (isNoApplyAction) return;

    let updatedList = isChecked
      ? union([permission], selectedState)
      : difference(selectedState, [permission]);

    updatedList = updateTasksPermissionsState(updatedList);
    updatedList = updateTemplatesPermissionsState(updatedList);

    const canManageLogSubmission = permissionsList?.['CHECKLIST']?.find(
      (item) => item?.title === PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION,
    );

    if (permission === canManageLogSubmission?.id && isChecked) {
      const canViewOwnSubmissions = permissionsList?.['CHECKLIST']?.find(
        (item) => item?.title === PERMISSIONS.CAN_VIEW_OWN_SUBMISSIONS,
      );
      updatedList.push(canViewOwnSubmissions?.id);
    }

    updatedList = updateViewSubmissionsPermissions(
      updatedList,
      permission,
      isChecked,
    );

    setSelectedState(updatedList);
    onSelectedChange?.(updatedList);
  };

  const isAllSelected = (parentKey: string): boolean => {
    return every(permissionsList[parentKey], (item) =>
      includes(selectedState, item.id),
    );
  };

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const searchTerm = event?.target?.value?.toLowerCase();
      setSearchedTerm(searchTerm);
      const filteredList = {};

      // Filter permissionsList based on search term
      Object.keys(permissionsList).forEach((key) => {
        const filteredItems = permissionsList[key].filter((item) =>
          item.name.toLowerCase().includes(searchTerm),
        );
        if (filteredItems.length > 0) {
          filteredList[key] = filteredItems;
        }
      });

      setFilteredPermissionsList(filteredList);
    },
    [permissionsList],
  );

  const isDisablePermission = (permission) => {
    const allTasksPermissionsSelected = filteredPermissionsList?.[
      'TASK'
    ]?.every((item) => selectedState?.includes(item?.id));

    if (allTasksPermissionsSelected) {
      return false;
    }

    if (
      TASKS_PERMISSIONS_ORDER?.[permission?.title]?.disableBy?.length === 0 &&
      TASKS_PERMISSIONS_ORDER?.[permission?.title]?.enableBy?.length === 0
    )
      return false;

    const disableByStatus = TASKS_PERMISSIONS_ORDER?.[
      permission?.title
    ]?.disableBy?.some((p) => {
      const permissionId = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === p,
      )?.id;

      const isSelected = selectedState?.includes(permissionId);
      return isSelected;
    });

    if (disableByStatus) return true;

    const enableByStatus = TASKS_PERMISSIONS_ORDER?.[
      permission?.title
    ]?.enableBy?.some((p) => {
      const permissionId = filteredPermissionsList?.['TASK']?.find(
        (item) => item?.title === p,
      )?.id;

      const isSelected = selectedState?.includes(permissionId);
      return isSelected;
    });

    if (enableByStatus) return false;
    else return true;
  };
  const isRadio = (permission) => {
    const type =
      TASKS_PERMISSIONS_ORDER[permission?.title]?.type ||
      TEMPLATES_PERMISSIONS_ORDER[permission?.title]?.type;
    return type === 'radio';
  };
  const isDisablePermissionChecklist = (permission) => {
    const allChecklistPermissionsSelected = filteredPermissionsList?.[
      'CHECKLIST'
    ]?.every((item) => selectedState?.includes(item?.id));

    if (allChecklistPermissionsSelected) {
      return false;
    }

    if (
      TEMPLATES_PERMISSIONS_ORDER?.[permission?.title]?.disableBy?.length ===
        0 &&
      TEMPLATES_PERMISSIONS_ORDER?.[permission?.title]?.enableBy?.length === 0
    )
      return false;

    const disableByStatus = TEMPLATES_PERMISSIONS_ORDER?.[
      permission?.title
    ]?.disableBy?.some((p) => {
      const permissionId = filteredPermissionsList?.['CHECKLIST']?.find(
        (item) => item?.title === p,
      )?.id;

      const isSelected = selectedState?.includes(permissionId);
      return isSelected;
    });

    if (disableByStatus) return true;

    const enableByStatus = TEMPLATES_PERMISSIONS_ORDER?.[
      permission?.title
    ]?.enableBy?.some((p) => {
      const permissionId = filteredPermissionsList?.['CHECKLIST']?.find(
        (item) => item?.title === p,
      )?.id;

      const isSelected = selectedState?.includes(permissionId);
      return isSelected;
    });

    if (enableByStatus) return false;
    else return true;
  };

  return (
    <div>
      {isSearch && ( // Render search box only if isSearch is true
        <SearchBox>
          <CustomInput
            id="customRolesPermissions"
            suppressErrorSpace={true}
            grayInput={false}
            fieldProps={{
              onChange: handleInputChange,
              startAdornment: <SearchIcon />,
              placeholder: 'Type to Search',
            }}
            label=""
          />
        </SearchBox>
      )}

      {searchedTerm && Object.keys(filteredPermissionsList)?.length === 0 ? (
        <EmptyCnt>
          <NoRecordsFound />
        </EmptyCnt>
      ) : (
        <PermissionsListingCnt sx={{ height }}>
          {Object.keys(filteredPermissionsList).map((key) => (
            <div key={key}>
              <MainPermission direction="row" gap="5px" alignItems="center">
                {key !== 'TASK' && key !== 'CHECKLIST' && (
                  <CustomCheckbox
                    edge="start"
                    checked={isAllSelected(key)}
                    disableRipple
                    alignItems={'flex-start'}
                    onChange={(event) => parentChangeHandler(key, event)}
                  />
                )}
                <Typography className="content">
                  {PERMISSIONS_ENTITY_KEYWORDS[key]}
                </Typography>
              </MainPermission>
              {filteredPermissionsList[key].map((item) => {
                let isDisabled = false;
                if (key === 'TASK') {
                  isDisabled = isDisablePermission(item);
                }

                if (key === 'CHECKLIST') {
                  isDisabled = isDisablePermissionChecklist(item);
                }
                const radio = isRadio(item) || false;
                return (
                  <>
                    {item?.title === PERMISSIONS.CAN_ONLY_VIEW_OWN_TASKS && (
                      <InfoBanner direction="row" alignItems="center" gap="7px">
                        <InfoIcon className="icon" />
                        <Typography className="content">
                          Please enable <b>Access Task</b> permission above to
                          be able to view tasks.
                        </Typography>
                      </InfoBanner>
                    )}
                    {item?.title === PERMISSIONS.CAN_MANAGE_TASKS && (
                      <InfoBanner direction="row" alignItems="center" gap="7px">
                        <InfoIcon className="icon" />
                        <Typography className="content">
                          Please enable <b>View Task</b> permission above to be
                          able to manage tasks.
                        </Typography>
                      </InfoBanner>
                    )}

                    {item?.title === PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION && (
                      <InfoBanner direction="row" alignItems="center" gap="7px">
                        <InfoIcon className="icon" />
                        <Typography className="content">
                          Templates <b>Visibility</b> and{' '}
                          <b>Edit permissions</b> can be configured individually
                          for each custom template.
                        </Typography>
                      </InfoBanner>
                    )}

                    {item?.title === PERMISSIONS.CAN_VIEW_ALL_SUBMISSIONS && (
                      <InfoBanner direction="row" alignItems="center" gap="7px">
                        <InfoIcon className="icon" />
                        <Typography className="content">
                          Can / Atleast select one{' '}
                          <b>View Submissions Permission</b> at a time.
                        </Typography>
                      </InfoBanner>
                    )}

                    <SubPermission
                      key={item.id}
                      direction="row"
                      gap="5px"
                      alignItems="start"
                    >
                      <CustomCheckbox
                        edge="start"
                        radio={radio}
                        checked={selectedState.includes(item.id)}
                        disableRipple
                        disabled={isDisabled}
                        alignItems={'flex-start'}
                        onChange={(event) => subChangeHandler(item.id, event)}
                      />

                      <Stack gap="1px">
                        <Typography
                          className="title"
                          style={{
                            ...(isDisabled && {
                              color: 'gray',
                            }),
                          }}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          className="description"
                          style={{
                            ...(isDisabled && {
                              color: 'gray',
                            }),
                          }}
                        >
                          {item?.description}
                        </Typography>
                      </Stack>
                    </SubPermission>
                  </>
                );
              })}
            </div>
          ))}
        </PermissionsListingCnt>
      )}
    </div>
  );
};

export default CustomRolesSetPermissionsListing;
