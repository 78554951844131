import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  [x: string]: any;
}

const DashboardsIcon = ({ filled, style = {}, ...rest }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M7.33333 11.9167V15.5833M14.6667 10.0833V15.5833M11 6.41667V15.5833M7.15 19.25H14.85C16.3901 19.25 17.1602 19.25 17.7485 18.9503C18.2659 18.6866 18.6866 18.2659 18.9503 17.7485C19.25 17.1602 19.25 16.3901 19.25 14.85V7.15C19.25 5.60986 19.25 4.83978 18.9503 4.25153C18.6866 3.73408 18.2659 3.31338 17.7485 3.04973C17.1602 2.75 16.3901 2.75 14.85 2.75H7.15C5.60986 2.75 4.83978 2.75 4.25153 3.04973C3.73408 3.31338 3.31338 3.73408 3.04973 4.25153C2.75 4.83978 2.75 5.60986 2.75 7.15V14.85C2.75 16.3901 2.75 17.1602 3.04973 17.7485C3.31338 18.2659 3.73408 18.6866 4.25153 18.9503C4.83978 19.25 5.60986 19.25 7.15 19.25Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'DashboardsIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.3522 1.8335H5.64817C5.16496 1.83348 4.74807 1.83347 4.40472 1.86152C4.04232 1.89113 3.68187 1.9565 3.33502 2.13323C2.81758 2.39688 2.39688 2.81758 2.13323 3.33502C1.9565 3.68187 1.89113 4.04232 1.86152 4.40472C1.83347 4.74807 1.83348 5.16493 1.8335 5.64813V16.3522C1.83348 16.8354 1.83347 17.2523 1.86152 17.5956C1.89113 17.958 1.9565 18.3185 2.13323 18.6653C2.39688 19.1828 2.81758 19.6034 3.33502 19.8671C3.68187 20.0438 4.04232 20.1092 4.40472 20.1388C4.74808 20.1669 5.16493 20.1668 5.64815 20.1668H16.3522C16.8354 20.1668 17.2523 20.1669 17.5956 20.1388C17.958 20.1092 18.3185 20.0438 18.6653 19.8671C19.1828 19.6034 19.6034 19.1828 19.8671 18.6653C20.0438 18.3185 20.1092 17.958 20.1388 17.5956C20.1669 17.2523 20.1668 16.8354 20.1668 16.3522V5.64815C20.1668 5.16493 20.1669 4.74808 20.1388 4.40472C20.1092 4.04232 20.0438 3.68187 19.8671 3.33502C19.6034 2.81758 19.1828 2.39688 18.6653 2.13323C18.3185 1.9565 17.958 1.89113 17.5956 1.86152C17.2523 1.83347 16.8354 1.83348 16.3522 1.8335ZM11.9168 6.41683C11.9168 5.91057 11.5064 5.50016 11.0002 5.50016C10.4939 5.50016 10.0835 5.91057 10.0835 6.41683V15.5835C10.0835 16.0898 10.4939 16.5002 11.0002 16.5002C11.5064 16.5002 11.9168 16.0898 11.9168 15.5835V6.41683ZM14.6668 9.16683C15.1731 9.16683 15.5835 9.57724 15.5835 10.0835V15.5835C15.5835 16.0898 15.1731 16.5002 14.6668 16.5002C14.1606 16.5002 13.7502 16.0898 13.7502 15.5835V10.0835C13.7502 9.57724 14.1606 9.16683 14.6668 9.16683ZM7.3335 11.0002C7.83976 11.0002 8.25016 11.4106 8.25016 11.9168V15.5835C8.25016 16.0898 7.83976 16.5002 7.3335 16.5002C6.82724 16.5002 6.41683 16.0898 6.41683 15.5835V11.9168C6.41683 11.4106 6.82724 11.0002 7.3335 11.0002Z"
        fill="#4E48FA"
      />
    </>,
    'DashboardsIcon',
  );
  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  ) : (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  );
};
export default DashboardsIcon;
