import { createSvgIcon } from '@mui/material';

interface PropTypes {
  filled?: boolean;
  [x: string]: any;
}

const CustomDashboardsIcon = ({ filled, style = {}, ...rest }: PropTypes) => {
  const IconCmp = createSvgIcon(
    <>
      <path
        d="M20.1667 9.99183V3.7585C20.1667 2.3835 19.58 1.8335 18.1225 1.8335H14.4192C12.9617 1.8335 12.375 2.3835 12.375 3.7585V9.99183C12.375 11.3668 12.9617 11.9168 14.4192 11.9168H18.1225C19.58 11.9168 20.1667 11.3668 20.1667 9.99183Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.1667 18.2415V16.5915C20.1667 15.2165 19.58 14.6665 18.1225 14.6665H14.4192C12.9617 14.6665 12.375 15.2165 12.375 16.5915V18.2415C12.375 19.6165 12.9617 20.1665 14.4192 20.1665H18.1225C19.58 20.1665 20.1667 19.6165 20.1667 18.2415Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.62516 12.0085V18.2418C9.62516 19.6168 9.0385 20.1668 7.581 20.1668H3.87766C2.42016 20.1668 1.8335 19.6168 1.8335 18.2418V12.0085C1.8335 10.6335 2.42016 10.0835 3.87766 10.0835H7.581C9.0385 10.0835 9.62516 10.6335 9.62516 12.0085Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.62516 3.7585V5.4085C9.62516 6.7835 9.0385 7.3335 7.581 7.3335H3.87766C2.42016 7.3335 1.8335 6.7835 1.8335 5.4085V3.7585C1.8335 2.3835 2.42016 1.8335 3.87766 1.8335H7.581C9.0385 1.8335 9.62516 2.3835 9.62516 3.7585Z"
        stroke="#757575"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </>,
    'CustomDashboardsIcon',
  );
  const FilledIconCmp = createSvgIcon(
    <>
      <path
        d="M19.7082 9.99183V3.7585C19.7082 2.3835 19.1215 1.8335 17.664 1.8335H13.9607C12.5032 1.8335 11.9165 2.3835 11.9165 3.7585V9.99183C11.9165 11.3668 12.5032 11.9168 13.9607 11.9168H17.664C19.1215 11.9168 19.7082 11.3668 19.7082 9.99183Z"
        fill="#4E48FA"
      />
      <path
        d="M10.0832 12.0085V18.2418C10.0832 19.6168 9.4965 20.1668 8.039 20.1668H4.33567C2.87817 20.1668 2.2915 19.6168 2.2915 18.2418V12.0085C2.2915 10.6335 2.87817 10.0835 4.33567 10.0835H8.039C9.4965 10.0835 10.0832 10.6335 10.0832 12.0085Z"
        fill="#4E48FA"
      />
      <path
        d="M19.7082 18.2417V15.675C19.7082 14.3 19.1215 13.75 17.664 13.75H13.9607C12.5032 13.75 11.9165 14.3 11.9165 15.675V18.2417C11.9165 19.6167 12.5032 20.1667 13.9607 20.1667H17.664C19.1215 20.1667 19.7082 19.6167 19.7082 18.2417Z"
        fill="#4E48FA"
      />
      <path
        d="M10.0832 6.32516V3.7585C10.0832 2.3835 9.4965 1.8335 8.039 1.8335H4.33567C2.87817 1.8335 2.2915 2.3835 2.2915 3.7585V6.32516C2.2915 7.70016 2.87817 8.25016 4.33567 8.25016H8.039C9.4965 8.25016 10.0832 7.70016 10.0832 6.32516Z"
        fill="#4E48FA"
      />
    </>,
    'CustomDashboardsIcon',
  );
  return filled ? (
    <FilledIconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  ) : (
    <IconCmp
      style={{ fill: 'none', ...style }}
      viewBox={'0 0 22 22'}
      {...rest}
    />
  );
};
export default CustomDashboardsIcon;
