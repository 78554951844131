// React
import { useState, useMemo } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Custom components
import SidebarItem from 'components/layout/sidebar/sidebarItem/SidebarItem';
import CustomDropdown from 'components/Dropdown/CustomDropdown';
import SubItemsList from './SubItemsList';

// Icons
import { appRoutes } from 'utils/globalVariable';
import ReportingIcon from 'components/Icons/sidebarIcons/ReportingIcon';

const SubItemsListWithPopper = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const currentPath = useLocation().pathname;

  const isActive = useMemo(
    () =>
      currentPath.includes(appRoutes.dashboards) ||
      currentPath.includes(appRoutes.customDashboards) ||
      currentPath.includes(appRoutes.templateExports),
    [currentPath],
  );

  return (
    <CustomDropdown
      onClickawayCallback={() => setAnchorEl(null)}
      popperProps={{
        anchorEl,
        open: Boolean(anchorEl),
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [110, -1],
            },
          },
        ],
        sx: {
          '& .MuiPaper-root': {
            width: 256,
            boxShadow: '0px 4px 16px 4px rgba(0,0,0,0.16)',
            padding: '20px 20px 20px 36px',
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
          },
        },
        content: <SubItemsList onClickItemCallback={() => setAnchorEl(null)} />,
      }}
      buttonRenderer={
        <SidebarItem
          title={'Reporting'}
          icon={<ReportingIcon filled={isActive} />}
          isSelected={isActive}
          sidebarOpen={false}
          onClick={(event: React.MouseEvent<HTMLElement>) =>
            setAnchorEl(anchorEl ? null : event.currentTarget)
          }
        />
      }
    />
  );
};

export default SubItemsListWithPopper;
