import authActionsTypes from 'store/actionTypes/authActionType';
import departmentActionTypes from 'store/actionTypes/tagActionType';
import serviceActionTypes from 'store/actionTypes/serviceActionType';
import userActionTypes from 'store/actionTypes/userActionType';
import propertyLayoutActionTypes from 'store/actionTypes/propertyLayoutActionType';
import taskActionTypes from 'store/actionTypes/taskActionType';
import checklistV2ActionType from 'store/actionTypes/checklistV2ActionType';
import messagingActionTypes from 'store/actionTypes/messagingActionTypes';
import teamsActionTypes from 'store/actionTypes/teamsActiontypes';
import dialogActionTypes from 'store/actionTypes/dialogActionTypes';
import uploadActionTypes from 'store/actionTypes/uploadActionTypes';
import timezoneActionTypes from 'store/actionTypes/timezoneActionTypes';
import workspaceActionTypes from 'store/actionTypes/workspaceActionTypes';
import assetActionTypes from 'store/actionTypes/assetActionTypes';
import locationActionTypes from 'store/actionTypes/locationActionTypes';
import topAlertActionTypes from 'store/actionTypes/topAlertActionTypes';
import billingActionTypes from 'store/actionTypes/billingActionTypes';
import calendlyActionTypes from 'store/actionTypes/calendlyActionTypes';
import paywallActionTypes from './paywallActionTypes';
import userConfigActionTypes from 'store/actionTypes/userConfigActionTypes';
import savedOptionsActionTypes from 'store/actionTypes/savedOptionActionTypes';
import sidebarConfigActionTypes from 'store/actionTypes/sidebarConfigActionTypes';
import workspaceConfigActionTypes from 'store/actionTypes/workspaceConfigActionTypes';
import locationsLevelsActionTypes from 'store/actionTypes/locationsLevelsActionTypes';
import taskTemplatesActionTypes from 'store/actionTypes/taskTemplatesActionTypes';
import ProjectsActionTypes from 'store/actionTypes/projectsActionTypes';
import SchedulesActionTypes from 'store/actionTypes/schedulesActionTypes';
import flaggedItemsCategoriesActionTypes from 'store/actionTypes/flaggedItemsCategoriesActionTypes';
import permissionsActionTypes from 'store/actionTypes/permissionsActionTypes';
import rolesActionTypes from 'store/actionTypes/rolesActionTypes';

const otherActionTypes = {
  SET_SEARCH_TEXT: 'SET_SEARCH_TEXT',
  SET_IS_FILTERS_OR_SEARCH: 'SET_IS_FILTERS_OR_SEARCH',
  SET_LOADER: 'SET_LOADER',
  SET_ATTACHMENTS_PREVIEW_DATA: 'SET_ATTACHMENTS_PREVIEW_DATA',
  SET_PAGE_TITLE: 'SET_PAGE_TITLE',
  SET_MENU_STATE: 'SET_MENU_STATE',
  SET_IS_FIRST_LOGIN: 'SET_IS_FIRST_LOGIN',
  SET_APP_STATE: 'SET_APP_STATE',
  SET_APP_ROUTE_HISTORY: 'SET_APP_ROUTE_HISTORY',
};
const actions = {
  ...otherActionTypes,
  ...locationActionTypes,
  ...assetActionTypes,
  ...authActionsTypes,
  ...departmentActionTypes,
  ...serviceActionTypes,
  ...userActionTypes,
  ...propertyLayoutActionTypes,
  ...taskActionTypes,
  ...checklistV2ActionType,
  ...messagingActionTypes,
  ...teamsActionTypes,
  ...dialogActionTypes,
  ...uploadActionTypes,
  ...topAlertActionTypes,
  ...timezoneActionTypes,
  ...workspaceActionTypes,
  ...billingActionTypes,
  ...calendlyActionTypes,
  ...paywallActionTypes,
  ...userConfigActionTypes,
  ...paywallActionTypes,
  ...savedOptionsActionTypes,
  ...sidebarConfigActionTypes,
  ...workspaceConfigActionTypes,
  ...locationsLevelsActionTypes,
  ...taskTemplatesActionTypes,
  ...ProjectsActionTypes,
  ...SchedulesActionTypes,
  ...flaggedItemsCategoriesActionTypes,
  ...permissionsActionTypes,
  ...rolesActionTypes,
};

export default actions;
