import { AssigneeDropdownCnt } from 'components/AllDialogs/tasks/createTask/createTask.style';
import { useContext } from 'react';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import { cloneDeep, union } from 'lodash';
import { Box, Divider } from '@mui/material';
import TaskAssignTo from 'components/TaskInputs/assignTo';
import TaskNotification from './taskNotification';
import { PROJECT_TYPES } from 'utils/globalVariable';

const TaskAssigneeDropdown = (props) => {
  const { hideElements, updateWatchers, projectType } = props;
  const { updateTask, newTask } = useContext(CreateTaskContext);

  const getNewOverdueWatchers = (assignees) => {
    const OldOverdueWatchers = cloneDeep(
      newTask?.notification?.overdue?.recipients || [],
    );

    const CurrentAssignees = cloneDeep(assignees || []);
    const finaldata = union(OldOverdueWatchers, CurrentAssignees);
    return finaldata || [];
  };

  const handleUpdateAssignee = (assignees) => {
    updateTask({
      assignees,
      isMultiTasks:
        assignees?.length < 2 ? false : newTask?.isMultiTasks || false,
      notification: {
        ...newTask?.notification,
        overdue: {
          recipients: getNewOverdueWatchers(assignees),
        },
      },
    });
  };

  const handleUpdateRole = (assigneesRoles) => {
    updateTask({
      assigneesRoles,
    });
  };

  return (
    <AssigneeDropdownCnt>
      <label>Assignee</label>

      {!hideElements?.watchers && (
        <Box className="watchersCnt">
          <TaskNotification
            updateWatchers={(watchers) =>
              updateWatchers(watchers, 'statusChange')
            }
            watchers={newTask.notification.statusChange.recipients}
          />
        </Box>
      )}

      <Divider className="separator" />

      <TaskAssignTo
        handleChangeUsersAndTeamsCallback={handleUpdateAssignee}
        handleChangeRolesCallback={handleUpdateRole}
        selectedUsersAndTeams={newTask?.assignees || []}
        selectedRole={newTask?.assigneesRoles}
        hideUsersAndTeams={projectType === PROJECT_TYPES.roleBasedProject}
        hideRoles={projectType === PROJECT_TYPES.userBasedProject}
        hideTabs={[
          PROJECT_TYPES.roleBasedProject,
          PROJECT_TYPES.userBasedProject,
        ].includes(projectType)}
      />
    </AssigneeDropdownCnt>
  );
};

export default TaskAssigneeDropdown;
