import { FC, useMemo, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CrossIcon from 'components/Icons/crossIcon';
import RolesContent from './RolesContent';
import { CustomIconButton, SearchInputCnt, StyledSearchField } from './style';

// Interface for contentProps
interface ContentProps {
  style?: Record<string, any>;
}

// Interface for Props
interface IProps {
  data: Array<{ id: string; label: string }>;
  selected: Array<string>;
  changeHandler: (currentSelected: string) => void;
  contentProps?: ContentProps;
  containerProps?: Record<string, any>;
  searchQueryCallback?: (searchQuery: string) => void;
}

const RolesItemsContainer: FC<IProps> = ({
  data = [],
  selected,
  changeHandler,
  contentProps = {},
  containerProps = {},
  searchQueryCallback,
}) => {
  const customProps = { ...contentProps };
  const [searchQuery, setSearchQuery] = useState('');

  const transformedData = useMemo(() => {
    if (searchQuery) {
      return data.filter((item) =>
        item.label.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }
    return data;
  }, [data, searchQuery]);

  const changeHandlerCallback = (rowData: { selectedId: string }) => {
    changeHandler(rowData.selectedId);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    searchQueryCallback?.(query); // Callback for search query if needed
  };

  return (
    <div {...containerProps}>
      <SearchInputCnt>
        <StyledSearchField
          id="SearchInput"
          autoFocus
          startAdornment={<SearchIcon />}
          endAdornment={
            <CustomIconButton onClick={() => setSearchQuery('')}>
              <CrossIcon className="icon" />
            </CustomIconButton>
          }
          onChange={handleSearch}
          placeholder="Type to Search"
          value={searchQuery}
          fullWidth
        />
      </SearchInputCnt>

      <RolesContent
        data={transformedData}
        selected={selected}
        changeHandler={changeHandlerCallback}
        contentProps={customProps}
        searchQuery={searchQuery}
      />
    </div>
  );
};

export default RolesItemsContainer;
