import { useDispatch, useSelector } from 'react-redux';
import CustomDialog from 'components/CustomDialog/customDialog';
import { setDialog } from 'store/actions/dialogActions';
import { ContentCnt } from './style';
import CustomDialogHeader from 'components/CustomDialog/DialogHeader/dialogHeader.cmp';
import DIALOGS from 'utils/dialogIds';
import { Grid, Stack } from '@mui/material';
import CTypography from 'components/CTypography';

import selectors from 'store/selectors';
import useResponsive from 'utils/CustomHooks/useResponsive';
import AssetsIcon from 'components/Icons/assetsIcon';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import { PROJECT_TYPES } from 'utils/globalVariable';
import CustomRolesIcon from 'components/Icons/customRolesIcon';

const CreateProjectDialog = () => {
  const dispatch = useDispatch();
  const { isMobileDeviceSm } = useResponsive();

  const dialogState = useSelector(selectors.getDialogState);

  const { open, data = {} } = dialogState?.[DIALOGS?.CREATE_PROJECT] || [];
  const { createProjectCallback } = data;

  const handleClose = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: DIALOGS?.CREATE_PROJECT,
      }),
    );
  };

  const openFormHandler = (projectType) => {
    dispatch(
      setDialog({
        open: true,
        dialogId: 'createEditTask',
        data: {
          hideElements: {
            separateTasksCreation: true,
            autoTagLocation: true,
          },
          onSuccessCallBack: createProjectCallback,
          taskData: {
            recurringTask: true,
          },
          dialogState: {
            viewLess: false,
            isProject: true,
            projectType,
          },
        },
      }),
    );

    handleClose();
  };

  return (
    <CustomDialog
      header={
        <CustomDialogHeader
          onClose={handleClose}
          other={{
            style: { border: 'unset', cursor: 'pointer' },
          }}
        >
          Create Project
        </CustomDialogHeader>
      }
      hideActions={true}
      dialogProps={{
        open: open,
        maxWidth: false,
        onClose: handleClose,
        fullScreen: isMobileDeviceSm,
      }}
    >
      <ContentCnt>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              className="box"
              style={{ cursor: 'pointer' }}
              onClick={() => openFormHandler(PROJECT_TYPES.userBasedProject)}
            >
              <Stack alignItems={'center'} direction="row" gap="20px">
                <UsersAndTeamsIcon className="userIcon" />
                <div>
                  <CTypography className="title">
                    User-Based Project
                  </CTypography>
                  <CTypography className="subTitle">
                    Create a project with a separate schedule for each user,
                    team or location
                  </CTypography>
                </div>
              </Stack>
            </div>
          </Grid>
          <Grid item xs={12} className="boxCnt">
            <div
              className="box"
              onClick={() => openFormHandler(PROJECT_TYPES.assetBasedProject)}
            >
              <Stack alignItems={'center'} direction="row" gap="20px">
                <AssetsIcon className="assetIcon" />
                <div>
                  <CTypography className="title">
                    Asset-Based Project
                  </CTypography>
                  <CTypography className="subTitle">
                    Create a project with a separate schedule for each asset
                  </CTypography>
                </div>
              </Stack>
            </div>
          </Grid>

          <Grid item xs={12} className="boxCnt">
            <div
              className="box"
              onClick={() => openFormHandler(PROJECT_TYPES.roleBasedProject)}
            >
              <Stack alignItems={'center'} direction="row" gap="20px">
                <CustomRolesIcon className="roleIcon" />
                <div>
                  <CTypography className="title">
                    Role-Based Project
                  </CTypography>
                  <CTypography className="subTitle">
                    Create a project w.r.t role - user wise or site location
                    wise
                  </CTypography>
                </div>
              </Stack>
            </div>
          </Grid>
        </Grid>
      </ContentCnt>
    </CustomDialog>
  );
};

export default CreateProjectDialog;
