import { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Layout from 'components/layout/layout';
import withAuth from 'components/Routes/authHoc';
import withPublicRoute from 'components/Routes/withPublicRoute';
import ChecklistLogReport from 'components/ChecklistReport';
import withTitleHoc from 'components/Routes/withTitleHoc';
import { PAGE_TITLES } from 'utils/globalVariable';
import PublicTemplateSubmission from 'pages/PublicTemplateSubmission';
import PublicRequestorSubmission from 'pages/PublicRequesterSubmission';

const OnBoarding = lazy(() => import('pages/onboarding'));
const OnBoardingStatus = lazy(() =>
  import('pages/onboarding/OnBoardingStatus'),
);
const EmailConfirmation = lazy(() => import('pages/email-confirmation'));
const TaskHome = lazy(() => import('pages/index'));

const ChecklistV2 = lazy(() => import('pages/checklistV2'));
const Documents = lazy(() => import('pages/documents'));
const DocumentBuilder = lazy(() => import('pages/documents/builder'));
const DocumentDetail = lazy(() => import('pages/documents/detail'));
const ChecklistBuilder = lazy(() => import('pages/checklistV2/Builder/index'));

const Messages = lazy(() => import('pages/messages'));
const Guides = lazy(() => import('pages/guides'));
const AddOns = lazy(() => import('pages/AddOns'));
const Dashboard = lazy(() => import('pages/dashboard'));
const OrganizationManagement = lazy(() =>
  import('pages/organization-management'),
);
const KioskModeConfig = lazy(() => import('pages/KioskConfig'));
const ServiceManagement = lazy(() => import('pages/service-management'));

const TeamsDashboard = lazy(() => import('pages/teams/teams.view'));
const Settings = lazy(() => import('pages/settings/settings.view'));
const MySettings = lazy(() => import('pages/my-settings'));
const Login = lazy(() => import('pages/Login'));
const SetNewPassword = lazy(() => import('pages/Login/SetNewPassword'));
const SignUpWithEmail = lazy(() => import('pages/Login/signUpWithEmail'));

const CreateWorkspace = lazy(() => import('pages/CreateWorkspace'));
const InviteWorkspaceUsers = lazy(() =>
  import('pages/CreateWorkspace/InviteUsers'),
);
const MyWorkspaces = lazy(() => import('pages/MyWorkspaces'));
const NotificationSettings = lazy(() => import('pages/notification-settings'));
const LoginWithParams = lazy(() => import('pages/Login/loginWithParams'));

const TemplatePreview = lazy(() => import('pages/template-preview'));
const Billing = lazy(() => import('pages/Billing'));
const Locked = lazy(() => import('pages/PlanExpired'));

const LocationsView = lazy(() => import('pages/Locations/index'));
const Verify = lazy(() => import('pages//verify'));
const TemplateDashboard = lazy(() => import('pages/TemplateDashboard'));
const InternalSubmissionShare = lazy(() =>
  import('pages/InternalSubmissionShare'),
);
const AssetsView = lazy(() => import('pages/Assets'));
const PricingStandalonePage = lazy(() => import('pages/PricingStandalonePage'));
const ReportingDashboard = lazy(() => import('pages/ReportingV2'));
const RequestListingDashboard = lazy(() => import('pages/Requests/Requests'));

const TaskActivityLogWebView = lazy(() =>
  import('pages/WebViews/task/activityLog'),
);
const ScheduledWork = lazy(() => import('pages/Schedules'));
const AgendaView = lazy(() => import('pages/Schedules/agendaView'));
const Projects = lazy(() => import('pages/Projects'));
const FullScreenSpreadsheet = lazy(() => import('pages/FullScreenSpreadsheet'));
const TemplateSubmissions = lazy(() =>
  import('pages/ReportingV2/Reports/TemplateSubmissions'),
);
const TemplateTableView = lazy(() =>
  import('pages/Templates/TemplateTableView'),
);
const Notifications = lazy(() => import('pages/Notifications'));
const FullScreenTemplate = lazy(() => import('pages/FullScreenTemplate'));

const UsersAndTeams = lazy(() => import('pages/UsersAndTeams'));
const FullScreenTemplateLibrary = lazy(() =>
  import('pages/FullScreenTemplateLibrary'),
);

const TemplateFileUpload = lazy(() =>
  import('components/BuilderUI/TemplateFileUpload'),
);
const TemplateFileUploadSuccess = lazy(() =>
  import('components/BuilderUI/TemplateFileUpload/successInfo'),
);
const AuthScreenDetails = lazy(() => import('components/BuilderUI/AuthScreen'));
const FileUploadTemplateMobile = lazy(() =>
  import('pages/FileUploadTemplateMobile'),
);
const Dashboards = lazy(() => import('pages/Dashboards'));
const CustomDashboards = lazy(() => import('pages/CustomDashboards'));

const ScheduleReporting = lazy(() =>
  import('pages/Dashboards/Reports/ScheduledWork'),
);
const ActivityLogsPage = lazy(() => import('pages/WorkspaceActivityLogs'));
const ProjectDetails = lazy(() => import('pages/ProjectDetails'));
const ScheduleDetails = lazy(() => import('pages/SchedulesDetails'));

const CustomRoleDetails = lazy(() => import('pages/roles/details'));
const SSOConfigrations = lazy(() => import('pages/SSO'));
const KioskModeDetails = lazy(() =>
  import('components/BuilderUI/AddOns/KioskMode'),
);
const MultiLocationsDetails = lazy(() =>
  import('components/BuilderUI/AddOns/MultiLocations'),
);
const CustomRolesDetails = lazy(() =>
  import('components/BuilderUI/AddOns/CustomRoles'),
);
const SSODetails = lazy(() => import('components/BuilderUI/AddOns/SSO'));
const WorkRequestsDetails = lazy(() =>
  import('components/BuilderUI/AddOns/WorkRequests'),
);
const ScanQRCodeDetails = lazy(() =>
  import('components/BuilderUI/AddOns/ScanQRCode'),
);
const ProjectsDetails = lazy(() =>
  import('components/BuilderUI/AddOns/Projects'),
);

const AddOnsMultiLocationsPage = lazy(() =>
  import('pages/AddOns/MultiLocations'),
);
const AddOnsKioskModePage = lazy(() => import('pages/AddOns/KioskMode'));
const AddOnsCustomRolesPage = lazy(() => import('pages/AddOns/CustomRoles'));
const AddOnsSSOPage = lazy(() => import('pages/AddOns/SSO'));
const AddOnsWorkRequestsPage = lazy(() => import('pages/AddOns/WorkRequests'));
const AddOnsScanQRCodePage = lazy(() => import('pages/AddOns/ScanQRCode'));
const AddOnsProjectsPage = lazy(() => import('pages/AddOns/Projects'));

const FilteredTasks = lazy(() => import('pages/FilteredTasks/FilteredTasks'));
const BluetoothSensorDetails = lazy(() =>
  import('components/BuilderUI/AddOns/BluetoothSensor'),
);

const AddOnsBluetoothSensorPage = lazy(() =>
  import('pages/AddOns/bluetoothSensor'),
);

const WithSuspense = ({ children }) => {
  return <Suspense fallback={<div></div>}>{children}</Suspense>;
};

const FeatureRedirectCmp = () => {
  <div></div>;
};
const FeatureRedirect = withAuth(FeatureRedirectCmp);
const LayoutComponentAuth = withAuth(Layout);
const InternalSubmissionShareAuth = withAuth(InternalSubmissionShare);
const SetPasswordCmp = withAuth(SetNewPassword);
const ChecklistLogReportCmp = ChecklistLogReport;
const LoginCmp = withPublicRoute(Login);
const TemplateFileUploader = withPublicRoute(TemplateFileUpload);
const TemplateFileUploadSuccessInfo = withPublicRoute(
  TemplateFileUploadSuccess,
);
const AuthScreenInfo = withPublicRoute(AuthScreenDetails);
const SignupCmp = withPublicRoute(SignUpWithEmail);
const OnBoardingCmp = OnBoarding;
const OnBoardingStatusCmp = OnBoardingStatus;

const EmailConfirmationCmp = withPublicRoute(EmailConfirmation);
const TaskHomeCmp = withTitleHoc(TaskHome);
const MyWorkspacesCmp = withTitleHoc(MyWorkspaces);
const NotificationSettingsCmp = withTitleHoc(NotificationSettings);
const ServiceManagementCmp = withTitleHoc(ServiceManagement);
const OrganizationManagementCmp = withTitleHoc(OrganizationManagement);
const KioskModeConfigurationCmp = withTitleHoc(KioskModeConfig);
const MySettingsCmp = withTitleHoc(MySettings);
const TeamsDashboardCmp = withTitleHoc(TeamsDashboard);
const SettingsCmp = withTitleHoc(Settings);
const MessagesCmp = withTitleHoc(Messages);
const GuidesCmp = withTitleHoc(Guides);
const DashboardCmp = withTitleHoc(Dashboard);
const AddOnsCmp = withTitleHoc(AddOns);
const AddOnsMultiLocationsCmp = withTitleHoc(AddOnsMultiLocationsPage);
const AddOnsBluetoothSensorCmp = withTitleHoc(AddOnsBluetoothSensorPage);
const AddOnsKioskModeCmp = withTitleHoc(AddOnsKioskModePage);
const AddOnsCustomRolesCmp = withTitleHoc(AddOnsCustomRolesPage);
const AddOnsSSOCmp = withTitleHoc(AddOnsSSOPage);
const AddOnsWorkRequestsCmp = withTitleHoc(AddOnsWorkRequestsPage);
const AddOnsScanQRCodeCmp = withTitleHoc(AddOnsScanQRCodePage);
const AddOnsProjectsCmp = withTitleHoc(AddOnsProjectsPage);

const ChecklistBuilderCmp = withTitleHoc(ChecklistBuilder);
const DocumentsBuilderCmp = withTitleHoc(DocumentBuilder);
const RequestListingDashboardCmp = withTitleHoc(RequestListingDashboard);
const ActivityLogsCmp = withTitleHoc(ActivityLogsPage);
const SSOConfigrationsCmp = withTitleHoc(SSOConfigrations);
const ChecklistV2Cmp = withTitleHoc(ChecklistV2);
const DocumentsCmp = withTitleHoc(Documents);
const ScheduledWorkCmp = withTitleHoc(ScheduledWork);
const AgendaViewCmp = withTitleHoc(AgendaView);
const ProjectsWorkCmp = withTitleHoc(Projects);
const ProjectDetailsCmp = withTitleHoc(ProjectDetails);
const ScheduleDetailsCmp = withTitleHoc(ScheduleDetails);
const ReportingDashboardCmp = withTitleHoc(ReportingDashboard);
const DashboardsCmp = withTitleHoc(Dashboards);
const BillingView = withAuth(withTitleHoc(Billing));
const LockedView = withAuth(Locked);
const PricingView = withAuth(PricingStandalonePage);
const LocationsCmp = withTitleHoc(LocationsView);
const AssetsCmp = withTitleHoc(AssetsView);
const SubmissionsReport = withTitleHoc(TemplateSubmissions);
const TemplatesTableView = withTitleHoc(TemplateTableView);
const CustomDashboardsCmp = withTitleHoc(CustomDashboards);
const UsersAndTeamsView = withTitleHoc(UsersAndTeams);
const CustomRoleDetailsView = withTitleHoc(CustomRoleDetails);
const ScheduledTasksReporting = withTitleHoc(ScheduleReporting);

const AddonKioskModeInfo = withPublicRoute(KioskModeDetails);
const AddonMultiLocationsInfo = withPublicRoute(MultiLocationsDetails);
const AddonBluetoothSensorInfo = withPublicRoute(BluetoothSensorDetails);
const AddonCustomRolesInfo = withPublicRoute(CustomRolesDetails);
const AddonSSOInfo = withPublicRoute(SSODetails);
const AddonWorkRequestsInfo = withPublicRoute(WorkRequestsDetails);
const AddonScanQRCodeInfo = withPublicRoute(ScanQRCodeDetails);
const AddonProjectsDetailsInfo = withPublicRoute(ProjectsDetails);

const {
  task,
  checklist,
  documents,
  reporting,
  dashboard,
  messages,
  settings,
  notifications,
  teams,
  myProfile,
  myWorkspaces,
  newChecklist,
  requests,
  organizationManagement,
  kioskMode,
  locations,
  assets,
  serviceManagement,
  billing,
  guides,
  dashboards,
  customDashboards,
  templateExports,
  activityLogs,
  sso,
  roles,
  'scheduled-work': scheduledWork,
  projects,
  usersAndTeams,
  addOns,
} = PAGE_TITLES;
function AppRoutes() {
  return (
    <Routes>
      <Route
        path="/fr/:id*"
        element={
          <WithSuspense>
            <FeatureRedirect />
          </WithSuspense>
        }
      />
      <Route
        path="/"
        element={
          <WithSuspense>
            <Navigate to={'/sign-in'} />
          </WithSuspense>
        }
      />
      <Route
        path="/ws/:workspaceId/plan-expired"
        element={
          <WithSuspense>
            <LockedView />
          </WithSuspense>
        }
      />
      <Route
        path="/ws/:workspaceId/pricing"
        element={
          <WithSuspense>
            <PricingView />
          </WithSuspense>
        }
      />
      <Route
        path="/ws/:workspaceId/new"
        element={
          <WithSuspense>
            <CreateWorkspace />
          </WithSuspense>
        }
      ></Route>
      <Route
        path="/ws/:workspaceId/invites-users"
        element={
          <WithSuspense>
            <InviteWorkspaceUsers />
          </WithSuspense>
        }
      />
      <Route path="/ws/:workspaceId" element={<LayoutComponentAuth />}>
        <Route
          path="dashboard"
          element={
            <WithSuspense>
              <DashboardCmp title={dashboard} />
            </WithSuspense>
          }
        />
        <Route
          path="tasks/*"
          element={
            <WithSuspense>
              <TaskHomeCmp title={task} />
            </WithSuspense>
          }
        />

        {/* Add Ons */}
        <Route
          path="addons"
          element={
            <WithSuspense>
              <AddOnsCmp title={addOns} />
            </WithSuspense>
          }
        />
        <Route
          path="addons/multilocations"
          element={
            <WithSuspense>
              <AddOnsMultiLocationsCmp renderGoBackIcon title={addOns} />
            </WithSuspense>
          }
        />
        <Route
          path="addons/bluetoothsensor"
          element={
            <WithSuspense>
              <AddOnsBluetoothSensorCmp renderGoBackIcon title={addOns} />
            </WithSuspense>
          }
        />
        <Route
          path="addons/kioskmode"
          element={
            <WithSuspense>
              <AddOnsKioskModeCmp renderGoBackIcon title={addOns} />
            </WithSuspense>
          }
        />
        <Route
          path="addons/customroles"
          element={
            <WithSuspense>
              <AddOnsCustomRolesCmp renderGoBackIcon title={addOns} />
            </WithSuspense>
          }
        />
        <Route
          path="addons/sso"
          element={
            <WithSuspense>
              <AddOnsSSOCmp renderGoBackIcon title={addOns} />
            </WithSuspense>
          }
        />
        <Route
          path="addons/workrequests"
          element={
            <WithSuspense>
              <AddOnsWorkRequestsCmp renderGoBackIcon title={addOns} />
            </WithSuspense>
          }
        />
        <Route
          path="addons/scanqrcode"
          element={
            <WithSuspense>
              <AddOnsScanQRCodeCmp renderGoBackIcon title={addOns} />
            </WithSuspense>
          }
        />
        <Route
          path="addons/projects"
          element={
            <WithSuspense>
              <AddOnsProjectsCmp renderGoBackIcon title={addOns} />
            </WithSuspense>
          }
        />

        <Route
          path="activity/task/:taskId"
          element={
            <WithSuspense>
              <TaskActivityLogWebView />
            </WithSuspense>
          }
        />
        <Route
          path="documents/:tab/*"
          element={
            <WithSuspense>
              <DocumentsCmp title={documents} />
            </WithSuspense>
          }
          exact
        />

        <Route
          path="documents/new"
          exact
          element={
            <WithSuspense>
              <DocumentsBuilderCmp title={documents} />
            </WithSuspense>
          }
        />

        <Route
          path="projects"
          exact
          element={
            <WithSuspense>
              <ProjectsWorkCmp title={scheduledWork} childTitle={projects} />
            </WithSuspense>
          }
        />

        <Route
          path="projects/:projectId"
          exact
          element={
            <WithSuspense>
              <ProjectDetailsCmp title={scheduledWork} childTitle={projects} />
            </WithSuspense>
          }
        />

        <Route
          path="schedule-details/:scheduleId"
          exact
          element={
            <WithSuspense>
              <ScheduleDetailsCmp title={scheduledWork} />
            </WithSuspense>
          }
        />

        <Route
          path="scheduled-work"
          exact
          element={
            <WithSuspense>
              <Navigate to={'manage-schedules'} replace={true} />
            </WithSuspense>
          }
        />

        <Route
          path="agenda-view"
          exact
          element={
            <WithSuspense>
              <Navigate to={'schedules'} replace={true} />
            </WithSuspense>
          }
        />

        <Route
          path="agenda-view/:tab"
          exact
          element={
            <WithSuspense>
              <AgendaViewCmp title={scheduledWork} childTitle="Agenda" />
            </WithSuspense>
          }
        />

        <Route
          path="scheduled-work/:tab"
          element={
            <WithSuspense>
              <ScheduledWorkCmp title={scheduledWork} childTitle="Schedules" />
            </WithSuspense>
          }
        />
        <Route
          path="reporting/*"
          element={
            <WithSuspense>
              <ReportingDashboardCmp title={reporting} />
            </WithSuspense>
          }
        />
        <Route
          path="dashboards/*"
          element={
            <WithSuspense>
              <DashboardsCmp title={dashboards} />
            </WithSuspense>
          }
        />
        <Route
          path="submission-reports/:reportId"
          element={
            <WithSuspense>
              <SubmissionsReport title={reporting} />
            </WithSuspense>
          }
        />

        <Route
          path="schedule-reports/:reportId"
          element={
            <WithSuspense>
              <ScheduledTasksReporting title={reporting} />
            </WithSuspense>
          }
        />

        <Route
          path="schedule-detail-report/:reportId"
          element={
            <WithSuspense>
              <ScheduledTasksReporting title={reporting} detail={true} />
            </WithSuspense>
          }
        />
        <Route
          path="checklist/:checklistId"
          element={
            <WithSuspense>
              <ChecklistBuilderCmp title={newChecklist} />
            </WithSuspense>
          }
        />
        <Route
          path="checklist/new"
          element={
            <WithSuspense>
              <ChecklistBuilderCmp title={newChecklist} />
            </WithSuspense>
          }
        />
        <Route
          path="checklist/new/folder/:folderId"
          element={
            <WithSuspense>
              <ChecklistBuilderCmp title={newChecklist} />
            </WithSuspense>
          }
        />
        <Route
          path="checklist/*"
          element={
            <WithSuspense>
              <ChecklistV2Cmp title={checklist} />
            </WithSuspense>
          }
        />
        <Route
          path="in-progress-checklist/:checklistId"
          element={
            <WithSuspense>
              <FullScreenTemplate />
            </WithSuspense>
          }
        />
        <Route
          path="checklist/:checklistId/folder/:folderId"
          element={
            <WithSuspense>
              <ChecklistBuilderCmp title={newChecklist} />
            </WithSuspense>
          }
        />
        <Route
          path="requests/*"
          element={
            <WithSuspense>
              <RequestListingDashboardCmp title={requests} />
            </WithSuspense>
          }
        />
        <Route
          path="filtered-tasks/*"
          element={
            <WithSuspense>
              <FilteredTasks />
            </WithSuspense>
          }
        />
        <Route
          path="templates/:templateId"
          element={
            <WithSuspense>
              <TemplateDashboard />
            </WithSuspense>
          }
        />
        <Route
          path="templates-table-view"
          element={
            <WithSuspense>
              <TemplatesTableView title={templateExports} />
            </WithSuspense>
          }
        />
        <Route
          path="custom-dashboards/*"
          element={
            <WithSuspense>
              <CustomDashboardsCmp title={customDashboards} />
            </WithSuspense>
          }
        />
        <Route
          path="templates/spreadsheet/:templateId"
          element={
            <WithSuspense>
              <FullScreenSpreadsheet />
            </WithSuspense>
          }
        />
        <Route
          path="messages"
          element={
            <WithSuspense>
              <MessagesCmp title={messages} />
            </WithSuspense>
          }
        />
        <Route
          path="guides"
          element={
            <WithSuspense>
              <GuidesCmp title={guides} />
            </WithSuspense>
          }
        />
        <Route
          path="messages/:channelId"
          element={
            <WithSuspense>
              <MessagesCmp title={messages} />
            </WithSuspense>
          }
        />
        <Route
          path="my-notifications"
          element={
            <WithSuspense>
              <Notifications />
            </WithSuspense>
          }
        />

        <Route
          path="settings"
          element={
            <WithSuspense>
              <SettingsCmp title={settings} childTitle={myWorkspaces} />
            </WithSuspense>
          }
        >
          <Route
            path="sso"
            element={
              <WithSuspense>
                <SSOConfigrationsCmp title={settings} childTitle={sso} />
              </WithSuspense>
            }
          />
          <Route
            path="activity-logs"
            element={
              <WithSuspense>
                <ActivityLogsCmp title={settings} childTitle={activityLogs} />
              </WithSuspense>
            }
          />

          <Route
            path="teams"
            element={
              <WithSuspense>
                <TeamsDashboardCmp title={settings} childTitle={teams} />
              </WithSuspense>
            }
          />
          <Route
            path="my-profile"
            element={
              <WithSuspense>
                <MySettingsCmp title={settings} childTitle={myProfile} />
              </WithSuspense>
            }
          />
          <Route
            path="organization-management"
            element={
              <WithSuspense>
                <OrganizationManagementCmp
                  title={settings}
                  childTitle={organizationManagement}
                />
              </WithSuspense>
            }
          />
          <Route
            path="kiosk"
            element={
              <WithSuspense>
                <KioskModeConfigurationCmp
                  title={settings}
                  childTitle={kioskMode}
                />
              </WithSuspense>
            }
          />
          <Route
            path="service-management"
            element={
              <WithSuspense>
                <ServiceManagementCmp
                  title={settings}
                  childTitle={serviceManagement}
                />
              </WithSuspense>
            }
          />
          <Route
            path="my-workspaces"
            element={
              <WithSuspense>
                <MyWorkspacesCmp title={settings} childTitle={myWorkspaces} />
              </WithSuspense>
            }
          />
          <Route
            path="notifications"
            element={
              <WithSuspense>
                <NotificationSettingsCmp
                  title={notifications}
                  childTitle={notifications}
                />
              </WithSuspense>
            }
          />
          <Route
            path="billing"
            element={
              <WithSuspense>
                <BillingView title={settings} childTitle={billing} showLoader />
              </WithSuspense>
            }
          />
        </Route>

        <Route
          path="locations/*"
          element={
            <WithSuspense>
              <LocationsCmp renderGoBackIcon title={locations} />
            </WithSuspense>
          }
        />
        <Route
          path="template-library"
          element={
            <WithSuspense>
              <FullScreenTemplateLibrary />
            </WithSuspense>
          }
        />
        <Route
          path="upload-file-template-mob"
          element={
            <WithSuspense>
              <FileUploadTemplateMobile />
            </WithSuspense>
          }
        />
        <Route
          path="assets/*"
          element={
            <WithSuspense>
              <AssetsCmp title={assets} />
            </WithSuspense>
          }
        />

        <Route
          path="users-and-teams"
          element={
            <WithSuspense>
              <UsersAndTeamsView title={usersAndTeams} />
            </WithSuspense>
          }
        />

        <Route
          path="users-and-teams/roles/:roleId"
          element={
            <WithSuspense>
              <CustomRoleDetailsView title={usersAndTeams} childTitle={roles} />
            </WithSuspense>
          }
        />
      </Route>

      <Route
        path="/public-report/:templateId"
        element={<PublicTemplateSubmission />}
      />
      <Route
        path="/ws/:workspaceId/public-request"
        element={<PublicRequestorSubmission />}
      />
      <Route
        path="/document-detail/:documentId"
        element={
          <WithSuspense>
            <DocumentDetail />
          </WithSuspense>
        }
      />
      <Route
        path="/set-password"
        element={
          <WithSuspense>
            <SetPasswordCmp />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/sign-in/*"
        element={
          <WithSuspense>
            <LoginCmp />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/register"
        element={
          <WithSuspense>
            <SignupCmp />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/on-boarding"
        element={
          <WithSuspense>
            <OnBoardingCmp />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/login-with-params"
        element={
          <WithSuspense>
            <LoginWithParams />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/on-boarding?email=email"
        element={
          <WithSuspense>
            <OnBoardingCmp />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/magic-link/sign-in"
        element={
          <WithSuspense>
            <OnBoardingStatusCmp />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/email-confirmation"
        element={
          <WithSuspense>
            <EmailConfirmationCmp />
          </WithSuspense>
        }
      />
      <Route
        path="/ws/:workspaceId/checklistlogs/:checklistLogId/report"
        element={<ChecklistLogReportCmp />}
      />
      <Route
        path="/public-report/:workspaceId/:checklistLogId"
        element={<ChecklistLogReportCmp />}
      />
      <Route
        exact
        path="/verify"
        element={
          <WithSuspense>
            <Verify />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/templates/:templateId/preview"
        element={
          <WithSuspense>
            <TemplatePreview />
          </WithSuspense>
        }
      />
      <Route
        path="/ws/:workspaceId/:templateId/:logId"
        element={
          <WithSuspense>
            <InternalSubmissionShareAuth />
          </WithSuspense>
        }
      />

      <Route
        exact
        path="/__builder_editing__/file-upload-template"
        element={
          <WithSuspense>
            <TemplateFileUploader />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/template-file-upload-success-section"
        element={
          <WithSuspense>
            <TemplateFileUploadSuccessInfo />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/auth-screen-info"
        element={
          <WithSuspense>
            <AuthScreenInfo />
          </WithSuspense>
        }
      />

      <Route
        exact
        path="/__builder_editing__/multi-location-details"
        element={
          <WithSuspense>
            <AddonMultiLocationsInfo />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/bluetooth-sensor-details"
        element={
          <WithSuspense>
            <AddonBluetoothSensorInfo />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/kiosk-mode-details"
        element={
          <WithSuspense>
            <AddonKioskModeInfo />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/custom-roles-details"
        element={
          <WithSuspense>
            <AddonCustomRolesInfo />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/single-sign-on-details"
        element={
          <WithSuspense>
            <AddonSSOInfo />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/work-requests-details"
        element={
          <WithSuspense>
            <AddonWorkRequestsInfo />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/scan-qr-code-details"
        element={
          <WithSuspense>
            <AddonScanQRCodeInfo />
          </WithSuspense>
        }
      />
      <Route
        exact
        path="/__builder_editing__/projects-details"
        element={
          <WithSuspense>
            <AddonProjectsDetailsInfo />
          </WithSuspense>
        }
      />
    </Routes>
  );
}

export default AppRoutes;
