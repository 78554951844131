import config from 'config/index';
import { ListBodyParam } from 'models/index.m';
import appConstants from 'utils/appConstants';

export const SUPPORTED_IMAGE_FILE = 'png,jpeg,jpg';

export const API_ROUTES = {
  dashboards: 'dashboards',
  lookups: '/lookups',
  baseHotelUrl: 'hotel/hotels',
  baseWorkspaceUrl: 'hotel/workspaces',
  loginMagicLink: 'hotel/auth/magic_link/send_login',
  sendOtpLogin: 'hotel/auth/otp/sms/send_login',
  verityOtp: 'hotel/auth/otp/sms/login',
  teams: `hotel/hotels`,
  configs: `hotel/config`,
  getTaskColumns: 'hotel/config/home-page-view',
  userMeta: 'hotel/config/user-general-config',
  updateTaskColumns: 'hotel/config/home-page-view',
  getCumulSSOToken: 'hotel/cumul/token',
  login: 'hotel/login',
  userContext: 'hotel/user-context',
  logout: 'hotel/logout',
  userinfo: 'hotel/getHotelDetail',
  updateUserProfilePic: 'admin/fileUpload',
  updateUserProfileInfo: 'hotel/userEditProfile/',
  deviceToken: 'hotel/setFcmToken/',
  tagList: 'hotel/getTagsList',
  tagListFront: 'task/getTagsList',
  addTag: 'hotel/addTags',
  editTag: 'hotel/updateTags/',
  removeTag: 'hotel/deleteTags/',
  singleTag: 'hotel/getTags/',
  serviceList: 'hotel/serviceTypeList',
  serviceListTask: 'task/serviceTypeList',
  addService: 'hotel/addServiceType',
  editService: 'hotel/updateServiceType/',
  removeService: 'hotel/deleteServiceType/',
  singleService: 'hotel/serviceTypeDetails/',
  getFlatFileToken: 'hotel/flatfile-token',
  userList: 'hotel/userList',
  userListTask: 'task/userList',
  userAssignAdmin: 'hotel/setAdmin/',
  addUser: 'admin/userRegister',
  inviteUser: 'admin/userRegister',
  verifyEmail: 'admin/verify-email',
  createInvitedUser: 'admin/create-invited-user',
  resendInvite: (hotelId, userId) => `admin/hotel/${hotelId}/user/${userId}`,
  sendEmailVerification: (hotelId, userId) =>
    `admin/hotel/${hotelId}/user/${userId}/send-verification`,
  acceptInvite: (wsId) => `admin/workspaces/${wsId}/accept-invite`,
  declineInvite: (wsId) => `admin/workspaces/${wsId}/decline-invite`,
  editUser: 'hotel/userEditProfile/',
  removeUser: 'hotel/removeUser/',
  userManagement: {
    base: 'hotel/hotels/',
    forgotInstructions: 'hotel/auth/magic_link/send_login',
  },
  userDepartmentList: 'hotel/userDepartmentList',
  userDetailSingleListing: 'hotel/userProfile/',
  forgotPassword: 'hotel/forgotPassword',
  changePassword: 'hotel/changePassword',
  getUserProfile: 'hotel/userProfile/',
  getMyWorkspaces: 'hotel/workspaces/mine',
  propertyLayoutFloorListing: 'hotel/allFloorList',
  propertyLayoutFloorListingTask: 'task/floorList',
  propertyLayoutFloorSingleListing: 'hotel/floorDetails/',
  propertyLayoutFloorAdding: 'hotel/addFloor',
  propertyLayoutFloorediting: 'hotel/updateFloor/',
  propertyLayoutFloorRemoving: 'hotel/deleteFloor/',
  propertyLayoutAreaListing: 'hotel/areaList',
  propertyLayoutAreaListingTask: 'task/areaList',
  propertyLayoutAreaSingleListing: 'hotel/areaDetail/',
  propertyLayoutAreaAdding: 'hotel/addArea',
  propertyLayoutAreaEditing: 'hotel/updateArea/',
  propertyLayoutAreaRemoving: 'hotel/deleteArea/',
  propertyLayoutRoomListing: 'hotel/roomList',
  propertyLayoutRoomListingTask: 'task/roomList',
  propertyLayoutRoomAdding: 'hotel/addRoom',
  propertyLayoutRoomRemoving: 'hotel/deleteRoom/',
  propertyLayoutRoomSingleListing: 'hotel/roomDetails/',
  propertyLayoutRoomEditing: 'hotel/updateRoom/',
  propertyLayoutBedtypeListing: 'hotel/bedType',
  OrgMgmtOrgNameChange: 'hotel/updateHotelName/',
  createTask: 'task/createTask',
  createRequest: 'task/createRequest',
  updateTask: 'task/editTask',
  createTaskUploadAttchment: 'task/taskMultipleFileUpload',
  taskList: 'task/tasks/list',
  taskDetailSingleListing: 'task/taskDetails/',
  taskStatusChange: 'task/setTaskStatus/',
  taskReopen: 'task/setReOpenTask/',
  taskAssignReassign: 'task/setAssigneAndReassigne/',
  taskArchive: 'task/setArchiveTask/',
  taskCommentListing: 'task/getCommentList/',
  taskChecklistItemStatusUpdate: 'task/setChecklistStatus/',
  TaskCommentAdd: 'task/createTaskComment',
  notificationListing: 'task/getNotificationList',
  notificationUnreadCount: 'task/notifications/unread/count',
  notificationMarkAsRead: 'task/updateNotification/',
  checklistFolderList: 'task/folderList',
  checklistFolderDetailedList: 'task/folderDetailedList',
  foldersChecklistList: 'task/checkList',
  checklistItemsList: 'task/itemCheckList',
  addChecklistFolder: 'task/addFolder',
  addChecklist: 'task/addCheckList',
  updateChecklistFolder: 'task/updateFolder/',
  updateChecklistItem: 'task/updateItemCheckList/',
  updateChecklistname: 'task/updateCheckList/',
  removeChecklistFolder: 'task/deleteFolder/',
  removeChecklist: 'task/deleteCheckList/',
  removeChecklistItem: 'task/deleteItemCheckList/',
  taskDetailChecklistListapi: 'task/taskCheckListDetails/',
  sendNotification: 'task/sendNotification',
  updateFolderOrder: 'task/update-folder-order',
  updateChecklistItemOrder: 'task/update-checklist-order',
  updateMyStatus: 'hotel/update-availability',
  checklists: 'task/checklists',
  publicChecklists: 'task/public-checklists',
  checklistCategories: 'task/checklist-categories',
  checklistTypes: 'task/checklist-types',
  checklistIndustries: 'task/checklist-industries',
  taskChecklist: 'task/tasks',
  templateGroupById: (id) => `task/checklist-groups/${id}`,
  hotels: 'task/hotels',
  fixHotelStreamUsers: (hotelId) => `hotel/hotels/${hotelId}/fix-stream-users`,
  createWorkspace: (hotelId) => `hotel/hotels/${hotelId}/workspaces`,
  editWorkspace: (hotelId) => `hotel/hotels/${hotelId}/workspaces`,
  deleteWorkspace: (hotelId) => `hotel/hotels/${hotelId}/workspaces`,
  workspacesUnreadCount: `workspaces/notifications/unread-count`,
  connectAIModel: 'hotel/ai/chat',
  generateAIContent: 'hotel/ai',
  aiUsage: 'hotel/ai/usage',
  generateAIContentByDocument: 'hotel/ai/file',
  timezones: 'admin/timezones',
  billing: {
    createCheckoutSession: (hotelId) =>
      `hotel/workspaces/${hotelId}/stripe-checkout`,
    createCustomerPortal: (hotelId) =>
      `hotel/workspaces/${hotelId}/stripe-customer-portal`,
    downgradeToFreeForeverPlan: (hotelId) =>
      `hotel/workspaces/${hotelId}/subscribe-freemium`,
    downgradePlan: (hotelId: string, planId: string) =>
      `hotel/workspaces/${hotelId}/subscriptions/${planId}/change`,
    getFreemiumPlanDetails: () => `hotel/freemium`,
    getSubscriptionInfo: (hotelId) =>
      `hotel/workspaces/${hotelId}/subscription-info`,
    getStripeProducts: (hotelId) =>
      `hotel/workspaces/${hotelId}/stripe-products`,
    updateSubscriptionQty: ({
      hotelId,
      subscriptionId,
      quantity,
    }: {
      hotelId: string;
      subscriptionId: string;
      quantity: number;
    }) =>
      `hotel/workspaces/${hotelId}/subscriptions/${subscriptionId}/quantity/${quantity}`,
  },
  roles: {
    inviteCandidate: (hotelId) =>
      `hotel/workspaces/${hotelId}/roles/invite-candidate`,
  },
};

export const LOCALSTORAGE_KEYS = {
  ACCESS_TOKEN: 'access_token',
  MASTER_HOTEL_ID: 'hid',
  EXPIRES_IN: 'expires_in',
  USER_PROFILE_ID: 'uid',
  REMEMBER_EMAIL: 'rememberEmail',
  FORGOT_EMAIL: 'forgotEmail',
  REMEMBER_PASSWORD: 'rememberPassword',
  DEVICE_TOKEN: 'deviceToken',
  ANON_ID: 'anonymousid',
  TEMPLATES_SELECTED_VIEW: 'templatesSelectedView',
  NOTIFICATION_BANNER_VISIBILITY: 'NOTIFICATION_BANNER_VISIBILITY',
  DOCUMENTS_SELECTED_VIEW: 'documentsSelectedView',
  AI_PROMPT: 'aiPrompt',
};

export const appRoutes = {
  locations: '/locations',
  assets: '/assets',
  requests: '/requests',
  filteredTasks: '/filtered-tasks',
  dashboards: '/dashboards',
  customDashboards: '/custom-dashboards',
  templateExports: '/templates-table-view',
};
export const PAGE_TITLES = {
  dashboard: 'Dashboard',
  projects: 'Projects',
  'scheduled-work': 'Scheduled Work',
  task: 'Tasks & Work Orders',
  checklist: appConstants.checklist.plural.base,
  newChecklist: `${appConstants.checklist.singular.base} Builder`,
  documents: 'Documents',
  requests: `Work Requests`,
  messages: 'Chats',
  guides: 'Video Guides',
  organizationManagement: 'Organization',
  kioskMode: 'Kiosk Mode',
  userManagement: 'Users',
  propertyLayout: 'Configuration',
  locations: 'Locations',
  assets: 'Assets',
  serviceManagement: 'Categories',
  teams: 'Teams',
  myProfile: 'My Settings',
  myWorkspaces: 'My Workspaces',
  settings: 'Settings',
  notifications: 'Notifications',
  taskCalendar: 'Task Calendar',
  taskList: 'Task List',
  billing: 'Billing',
  reporting: 'Reporting',
  configurations: 'Configurations',
  usersAndTeams: 'Users & Teams',
  taskTemplates: 'Saved Task Templates',
  dashboards: 'Dashboards',
  customDashboards: 'Custom Dashboards',
  templateExports: 'Template Exports',
  activityLogs: 'Activity Logs',
  sso: 'Single Sign-On (SSO)',
  roles: 'Roles',
  addOns: 'Xenia Add-ons',
  corrective: 'Corrective',
  requested: 'Requested',
};

export const defaultListApiParam: ListBodyParam = {
  page: 1,
  pageSize: 1000000,
  searchText: '',
  type: 'ALL',
  sort: {
    createdAt: 'DESC',
  },
};

export const Floor_AREA_LIST_API_PARAM: ListBodyParam = {
  page: 1,
  pageSize: 10000,
  searchText: '',
  sort: {
    createdAt: 'DESC',
  },
};

export const VALIDATION_ERROR_MESSAGES = {
  TASK_TITLE_REQUIRED: 'Task title is required',
  REQUEST_TITLE_REQUIRED: 'Request title is required',
  TASK_TITLE_CHARACTER_LIMIT: 'Title must be less or equal to 250 characters',
  REQUIRED_TAG_NAME: 'Please enter tag name.',
  REQUIRED_SERVICE_NAME: 'Please enter service type name.',
  VALID_TAG_NAME: 'Please enter valid tag name.',
  VALID_SERVICE_NAME: 'Please enter valid service type name.',
  SELECT_TAG_NAME: 'Please select tag.',
  PROVIDE_CURR_PASSWORD: 'Please enter current password.',
  PROVIDE_VALID_PASSWORD: 'Invalid Password',
  PROVIDE_PASSWORD_NEW: 'Please enter new password.',
  PROVIDE_PASSWORD: 'Please enter password.',
  PASSWORD_MIN_MAX_LIMIT: 'Password range must be 6-80 characters',
  PASSWORD_NOT_MATCHED: 'Password and confirm password do not match.',
  PROVIDE_CONFIRMPSW: 'Please enter confirm password.',
  PROVIDE_EMAIL: 'Please enter email.',
  PROVIDE_PHONE: 'Please enter phone.',
  PROVIDE_VALID_PHONE: 'Invalid Phone Number',
  PROVIDE_EMAIL_OR_PHONE: 'Please enter email or phone.',
  PROVIDE_VALID_EMAIL: 'Invalid Email',
  PROVIDE_VALID_IMAGE_SIZE: 'Image size should be within 300KB .',
  PROVIDE_VALID_IMAGE_TYPE: `Invalid file format(only ${SUPPORTED_IMAGE_FILE} are supported).`,

  REQUIRED_FIRST_NAME: 'Please enter first name.',
  REQUIRED_LAST_NAME: 'Please enter last name.',
  REQUIRED_EMAIL_ID: 'Please enter your email address',
  REQUIRED_PASSWORD_ID: 'Password Field is required',
  REQUIRED_NEW_PASSWORD_ID: 'Password Field is required',
  REQUIRED_CONFIRM_PASSWORD_ID: 'Password Field is required',
  REQUIRED_TITLE: 'Please enter user title.',
  REQUIRED_USER_PHONE_NO: 'Please enter phone number.',
  REQUIRED_PROFILE: 'Please select user photo.',
  REQUIRED_USER_ROLE: 'Please select user role.',
  REQUIRED_USER_TAG: 'Please select user tag.',
  REQUIRED_STATUS: 'Please select status.',
  REQUIRED_IS_ADMIN: 'Please select isadmin.',

  REQUIRED_VALID_FIRST_NAME: 'Please enter valid first name.',
  REQUIRED_VALID_LAST_NAME: 'Please enter valid last name.',
  REQUIRED_VALID_EMAIL_ID: 'Please enter your email address',
  REQUIRED_VALID_TITLE: 'Please enter valid user title.',
  REQUIRED_VALID_USER_PHONE_NO:
    "Please include a ' + ' and your country code (US & Canada = 1) before your mobile number (no dashes, spaces, or parenthesis)",
  REQUIRED_VALID_PROFILE: 'Please select user valid profile.',
  REQUIRED_VALID_USER_ROLE: 'Please select valid user role.',
  REQUIRED_VALID_USER_TAG: 'Please select valid user tag.',
  VALID_ASSIGN_ADMIN_LENGTH: 'Please select at least 1 admin.',

  REQUIRED_ROOM_NUMBER: 'Please enter room number.',
  REQUIRED_BEDS: 'Please select beds.',
  REQUIRED_BED_SINGLE: 'Please select bed.',
  VALID_BED_COUNTER: 'Not valid.',
  DUPLICATE_BEDS: 'Duplicate beds selected.',
  REQUIRED_FLOORID: 'Please select floor.',

  REQUIRED_FLOOR_NAME: 'Please enter floor name.',
  REQUIRED_AREA_NAME: 'Please enter area name.',
  SUB_LOCATION_DUPLICATE_NAME:
    'This sub-location name already exists within this parent.',
  SUB_ASSET_DUPLICATE_NAME:
    'This sub-asset name already exists within this parent.',
  LOCATION_DUPLICATE_NAME: 'Please enter different Location name.',
  ASSET_DUPLICATE_NAME: 'Please enter different Asset name.',
  LOCATION_NAME_REQUIRED_NAME: 'Location Name is required.',
  ASSET_NAME_REQUIRED_NAME: 'Asset Name is required.',
  PARENT_LOCATION_NAME_REQUIRED_NAME: 'Parent Location Name is required.',
  PARENT_ASSET_NAME_REQUIRED_NAME: 'Parent Asset Name is required.',
  SUB_LOCATION_NAME_REQUIRED_NAME: 'Sub Location Name is required.',
  SUB_ASSET_NAME_REQUIRED_NAME: 'Sub Asset Name is required.',

  PROPER_FLOOR_NAME_LENGTH: 'Floor name length should be between 2 to 12 char.',
  PROPER_ROOM_NUMBER_LENGTH:
    'Room number length should be between 2 to 12 char.',
  PROPER_AREA_NAME_LENGTH: 'Area name length should be between 2 to 30 char.',
  TEMPLATE_FOLDER_NAME_REQUIRED_NAME: 'Folder Name is required.',

  DOCUMENT_FOLDER_NAME_REQUIRED_NAME: 'Folder Name is required.',

  DOCUMENT_FILE_NAME_REQUIRED_NAME: 'File Name is required.',
};

export const VALIDATION_SUCCESS_MESSAGES = {
  PASSWORD_UPDATED_SUCCESSFULLY: 'Password Updated Successfully.',
};

export const REGEX_VALIDATE_PASSWORD = /^.{6,}$/;

export const taskTimeRepeat = [
  { value: '', label: 'Select', isDisabled: true },
  { value: 'Weekly', label: 'Weekly' },
  { value: 'Monthly', label: 'Monthly' },
];
export const TaskweekSelect = [
  { value: 'Week1', label: 'Week' },
  { value: 'Weeks2', label: '2 Weeks' },
  { value: 'Weeks3', label: '3 Weeks' },
];

export const numberDays = [
  { value: '01', label: '1st' },
  { value: '02', label: '2nd' },
  { value: '03', label: '3rd' },
  { value: '04', label: '4th' },
  { value: '05', label: '5th' },
  { value: '06', label: '6th' },
  { value: '07', label: '7th' },
  { value: '08', label: '8th' },
  { value: '09', label: '9th' },
  { value: '10', label: '10th' },
  { value: '11', label: '11th' },
  { value: '12', label: '12th' },
  { value: '13', label: '13th' },
  { value: '14', label: '14th' },
  { value: '15', label: '15th' },
  { value: '16', label: '16th' },
  { value: '17', label: '17th' },
  { value: '18', label: '18th' },
  { value: '19', label: '19th' },
  { value: '20', label: '20th' },
  { value: '21', label: '21th' },
  { value: '22', label: '22th' },
  { value: '23', label: '23th' },
  { value: '24', label: '24th' },
  { value: '25', label: '25th' },
  { value: '26', label: '26th' },
  { value: '27', label: '27th' },
  { value: '28', label: '28th' },
  { value: '29', label: '29th' },
  { value: '30', label: '30th' },
  { value: '31', label: '31th' },
];

export const locationInitialData = {
  type: '',
  roomId: [],
  floorId: [],
  areaId: [],
  name: '',
  additionalDescription: '',
};

export const assignUserInitialData = '';

export const defaultReactSelect = {
  value: '',
  label: 'Select',
};

export const defaultErrorObject = {
  description: '',
  location: '',
  service: '',
  tag: '',
  recurringTaskTitle: '',
  requestThisTask: '',
  repeatUntill: '',
  assignEmp: '',
  weekDays: '',
};

export const TASK_STATUS = {
  Unassigned: 'Unassigned',
  Overdue: 'Overdue',
  Attention: 'Attention',
  NotStarted: 'Not Started',
  InProgress: 'In Progress',
  Paused: 'Paused',
  Completed: 'Completed',
  Rescheduled: 'Rescheduled',
  Incomplete: 'Incomplete',
  Open: 'Open',
  OnHold: 'On Hold',
  Missed: 'Missed',
};

export const DEFAULT_TIMEZONE = 'America/Chicago';

export const initDataCreateTask = {
  description: '',
  title: '',
  location: [locationInitialData],
  AssignId: assignUserInitialData,
  serviceTypeId: { value: '', label: 'Type to search categories' },
  attachment: [] as string[],
  checklistId: null,
  priority: 'None',
  startedAt: '',
  endDate: '',
  endTime: '',
  pauseTime: '',
  startDate: '',
  dueDate: '',
  startTime: '',
  dueTime: '',
  recurringTask: false,
  recurringOnDay: defaultReactSelect,
  recurringByEvery: TaskweekSelect[0] as any,
  requestThisTask: taskTimeRepeat[0] as any,
  intervalWeek: [] as string[],
  Area: null,
  Assginer: null,
  Assigned: null,
  Floor: null,
  Room: null,
  additionalDescription: null,
  createdAt: null,
  id: null,
  taskStatus: null,
  errorObject: defaultErrorObject,
};

export const ConstantChannelTypeString = {
  UNREAD: 'Unread',
  GROUP: 'Group',
  TASK: 'Task',
  DIRECT_MESSAGE: 'Direct-Message',
  CHANNEL: 'Channel',
  FEED_STREAM: 'FeedStream',
};

export const DATE_FORMAT = 'MMM DD YYYY';

export const firebaseConfig = {
  apiKey: config.REACT_APP_FIREBASE_API_KEY,
  authDomain: config.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: config.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: config.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: config.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: config.REACT_APP_FIREBASE_APP_ID,
  measurementId: config.REACT_APP_FIREBASE_MEASURE_ID,
};

export const streamChatType = {
  MESSAGING: 'messaging',
  TEAM: 'team',
  DUMMY: 'dummy',
  FeedStream: 'team',
};

export const streamConstant = {
  SUCCESS: 'success',
  ERROR: 'error',
  SAMEDAY: 'hh:mm A',
  LASTDAY: '[Yesterday]',
  LASTWEEK: '[Last] dddd',
  SAMEELSE: 'DD/MM/YYYY hh:mm a',
  REMOVE_TYPE_LEAVE: 'leave',
  UNREAD: 'Unread',
  DIRECT: 'Direct',
  ADD_MEMBER: 'addmember',
  TEAM: 'team',
};
export const streamMessages = {
  EMPLOYEE_ADDED_TO_CHAT_SUCCESS: 'Employee Added To Chat Successfully.',
  NEW_MEMBER_ADDDED_SUCCESS: 'New Members Added Successfully.',
  CHANNEL_ARCHIVE_SUCCESS: 'Channel Archived Successfully.',
  CHANNEL_UNARCHIVE_SUCCESS: 'Channel UnArchived Successfully.',
  MESSAGE_UPDATE_SUCCESS: 'Message Updated Successfully.',
  MESSAGE_DELETED_SUCCESS: 'Message Deleted Successfully.',
  CHANNEL_MUTE_SUCCESS: 'Channel Mute Successfully.',
  CHANNEL_UNMUTE_SUCCESS: 'Channel UnMute Successfully.',
  REMOVE_MEMBER_SUCCESS: 'Member Removed Successfully.',
  YOU_LEAVE_CHANNEL_SUCCESS: 'You Leave Channel Successfully.',
};

export const filesExtension = {
  pdf: 'pdf',
  xls: 'xls',
  ppt: 'ppt',
  zip: 'zip',
  mp4: 'mp4',
  txt: 'txt',
  doc: 'doc',
  docx: 'doc',
  svg: 'svg',
  xlsx: 'xls',
  rtf: 'rtf',
  csv: 'csv',
};

export const CHECKLIST_ITEM_TYPE = {
  HEADER: 'header',
  PASS_FAIL: 'passFail',
  TEXT_FIELD: 'textField',
  TAKE_PHOTO: 'takePhoto',
  PROCEDURE: 'procedure',
  NUMBER: 'number',
  COST: 'cost',
  TEMPERATURE: 'temperature',
  MULTIPLE_CHOICE: 'multipleChoice',
  SIGNATURE: 'signature',
  DATE_TIME: 'dateTime',
  INSTRUCTION: 'instruction',
  DROPDOWN: 'dropdown',
  GEO_STAMP: 'geoStamp',
  LOCATION: 'location',
  PHOTO_ANNOTATE: 'photoAnnotate',
};

export const GOALS = {
  operations: 'Operational Execution & Management',
  healthAndSafety: 'Environmental, Health & Safety',
  qualityAssurance: 'Quality Assurance / Quality Control',
  maintenance: 'Maintenance Management',
  unitOperations: 'Multi-Site/Unit Operations',
};

export const WSCONFIG = {
  KIOSK_MODE_CONFIG: 'kioskMode',
};

export const KIOSK_DEFAULT_CONFIG = {
  isEnable: false,
  redirectTo: 'home',
  takeSelfie: false,
  logoutInterval: {
    enable: false,
    duration: 15,
  },
  generationType: 'auto',
  digits: 6,
};

export enum UsersActivityEnum {
  Loggedin = 'login',
  Loggedout = 'logout',
  SubmittedSubmission = 'template_submission',
  ChangedStatus = 'task_status_change',
}

export const PROJECT_TYPES = {
  userBasedProject: 'user',
  assetBasedProject: 'asset',
  roleBasedProject: 'role',
};
