import React, { useEffect, useState, useMemo } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import CustomDialog from 'components/CustomDialog/customDialog';
import {
  AutoTagLocationContent,
  AutoTagLocationNote,
  CreateProjectDialogHeader,
  CreateTaskDialogHeader,
  FooterActionsStyled,
  ProjectIconCnt,
  TaskFormCnt,
} from 'components/AllDialogs/tasks/createTask/createTask.style';
import { CreateTaskContext } from 'components/AllDialogs/tasks/createTask/context/context';
import CreateTaskInput from 'components/AllDialogs/tasks/createTask/createTaskInput.cmp';
import TaskAssigneeDropdown from 'components/AllDialogs/tasks/createTask/taskAssigneeDropdown.cmp';
import TaskPriorityDropdown from 'components/AllDialogs/tasks/createTask/priorityDropdown.cmp';
import TaskCategoryDropdown from 'components/AllDialogs/tasks/createTask/categoryDropdown.cmp';
import TaskDescriptionInput from 'components/AllDialogs/tasks/createTask/taskDescriptionInput.cmp';
import ShowMoreLess from 'components/AllDialogs/tasks/createTask/showMoreLess.cmp';
import RepeatTaskToggle from 'components/AllDialogs/tasks/createTask/repeatTaskToggle.cmp';
import TaskAttachment from 'components/AllDialogs/tasks/createTask/taskAttachment.cmp';
import { useDispatch, useSelector } from 'react-redux';
import { getDialogState } from 'store/selectors/dialogSelectors';
import { setDialog } from 'store/actions/dialogActions';
import { PROJECT_TYPES, VALIDATION_ERROR_MESSAGES } from 'utils/globalVariable';
import AddChecklist from 'components/AllDialogs/tasks/createTask/addChecklist.cmp';
import useCreateTask from 'utils/CustomHooks/useCreateTask';
import xeniaApi from 'api/index';
import TaskDateTimePicker from 'components/AllDialogs/tasks/createTask/CreateTaskDateTimePicker.cmp';
import TaskLocationDropdown from 'components/AllDialogs/tasks/createTask/locationDropdown.cmp';
import moment from 'moment-timezone';
import classNames from 'classnames';
import useDateTime from 'utils/CustomHooks/useDateTime';
import TaskAssetDropdown from 'components/AllDialogs/tasks/createTask/assetDropdown.cmp';
import AssetInfo from 'components/AllDialogs/tasks/createTask/assetsInfo.cmp';
import SubmissionAttachment from './submissionLink.cmp';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import useWorkingHours from 'utils/CustomHooks/useWorkingHours';
import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import HeaderLogDetail from 'components/AllDialogs/tasks/createEditTaskComponents/headerLogDetail';
import BulkTaskCreationSetting from './bulkTaskCreationSetting.cmp';
import TaskSubmissionAfterDue from './taskSubmissionAfterDue';
import { Stack } from '@mui/material';
import TaskNotification from './taskNotification';
import OverdueTaskNotification from './overdueTaskNotification';
import selectors from 'store/selectors';
import Divider from '@mui/material/Divider';
import CustomButton from 'components/Button/CustomButton';
import { VerifyFeatureAccess } from 'components/UpSellModal/VerifyFeatureAccess';
import TaskTemplateDropdown from './templateDropdown';
import UsersAndTeamsIcon from 'components/Icons/sidebarIcons/usersAndTeamsIcon';
import AssetsIcon from 'components/Icons/sidebarIcons/assetsIcon';
import { getUserHotelTz } from 'store/selectors/auth';
import CustomRolesIcon from 'components/Icons/customRolesIcon';

const CreateTaskHeader = () => {
  return (
    <CreateTaskDialogHeader>
      <CreateTaskInput placeholder={'Name your task or work order'} />
    </CreateTaskDialogHeader>
  );
};

const CreateProjectHeader = ({
  tab = PROJECT_TYPES.userBasedProject,
  updateTabValueCB,
}) => {
  const [value, setValue] = React.useState(tab);

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    setValue(newValue);
    updateTabValueCB?.(newValue);
  };

  useEffect(() => {
    setValue(tab);
  }, [tab]);

  return (
    <CreateProjectDialogHeader>
      <Tabs
        sx={{
          padding: '0px 24px',
          borderBottom: '1px solid #E0E0E0',
          width: '100%',
          '& button.MuiTab-root': {
            textTransform: 'none',
            fontWeight: 600,
            fontSize: '14px',
            p: '11px 6px',
          },
        }}
        value={value}
        onChange={handleChange}
      >
        {value === PROJECT_TYPES.userBasedProject && (
          <Tab
            value={PROJECT_TYPES.userBasedProject}
            label={
              <ProjectIconCnt
                sx={{
                  ...(value === PROJECT_TYPES.userBasedProject && {
                    '& svg': {
                      '& path': {
                        stroke: '#6868FE',
                      },
                    },
                    '& .title': {
                      color: '#6868FE',
                    },
                  }),
                }}
              >
                <UsersAndTeamsIcon />
                <Typography className="title">User-Based Project</Typography>
              </ProjectIconCnt>
            }
          />
        )}

        {value === PROJECT_TYPES.assetBasedProject && (
          <Tab
            value={PROJECT_TYPES.assetBasedProject}
            label={
              <ProjectIconCnt
                sx={{
                  ...(value === PROJECT_TYPES.assetBasedProject && {
                    '& svg': {
                      '& path': {
                        stroke: '#6868FE',
                      },
                    },
                    '& .title': {
                      color: '#6868FE',
                    },
                  }),
                }}
              >
                <AssetsIcon />
                <Typography className="title">Asset-Based Project</Typography>
              </ProjectIconCnt>
            }
          />
        )}
        {value === PROJECT_TYPES.roleBasedProject && (
          <Tab
            value={PROJECT_TYPES.roleBasedProject}
            label={
              <ProjectIconCnt
                sx={{
                  ...(value === PROJECT_TYPES.roleBasedProject && {
                    '& svg': {
                      '& path': {
                        stroke: '#6868FE',
                      },
                    },
                    '& .title': {
                      color: '#6868FE',
                    },
                  }),
                }}
              >
                <CustomRolesIcon sx={{ fill: 'none' }} />
                <Typography className="title">Role-Based Project</Typography>
              </ProjectIconCnt>
            }
          />
        )}
      </Tabs>
      <Box p={'0px 24px 16px 24px'}>
        <CreateTaskInput placeholder={'Name your project'} />
      </Box>
    </CreateProjectDialogHeader>
  );
};

interface CreateTaskPropTypes {
  onClickViewWorkOrder?: (task?: any) => void;
}

function CreateTask({ onClickViewWorkOrder }: CreateTaskPropTypes) {
  const tz = useSelector(getUserHotelTz) || 'America/Chicago';
  const loggedInUser = useSelector(selectors.getUserProfile);
  const [projectTab, setProjectTab] = useState(PROJECT_TYPES.userBasedProject);
  const defaultTask = {
    isChecklistRequired: true,
    notification: {
      statusChange: {
        recipients: loggedInUser ? [loggedInUser.id] : [],
      },
      overdue: {
        recipients: [],
      },
    },
  };

  const { createTask } = useCreateTask();
  const [newTask, setNewTask] = useState<any>(defaultTask);
  const [errors, setErrors] = useState<any>({});
  const [uploadsChange, setUploadsChange] = useState<boolean>(false);
  const [taskTimeChange, setTaskTimeChange] = useState<boolean>(false);
  const [showLess, setShowLess] = useState<any>(true);
  const [taskTime, setTaskTime] = useState<any>({
    startTime: '',
    dueTime: '',
  });
  const [showRepeatTask, setShowRepeatTask] = useState<boolean>(false);
  const [attachments, setAttachments] = useState<[]>([]);
  const [initiateSchedule, setInitiateSchedule] = useState(true);
  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('md'));

  const { getDateInTz, getDateInUtc, getUtcToTz, getUtcToTzTime } =
    useDateTime();
  const [submitButtonLoading, setSubmitButtonLoading] =
    useState<boolean>(false);
  const [attachChecklistOpen, setAttachChecklistOpen] =
    useState<boolean>(false);
  const [attachedChecklist, setAttachedChecklist] = useState(null);
  const [isChecklistRequired, setIsChecklistRequired] = useState(true);
  const [isWeekTimeEdit, setIsWeekTimeEdit] = useState<any>({
    startTime: true,
    endTime: true,
  });
  const [isMonthTimeEdit, setIsMonthTimeEdit] = useState<any>({
    startTime: true,
    endTime: true,
  });
  const dialogState = useSelector(getDialogState);

  const [recurType, setRecurType] = useState('oneOff');

  const hideElements = dialogState?.createEditTask?.data?.hideElements ?? {};
  const actionBtn = dialogState?.createEditTask?.data?.actionBtn;
  const template = dialogState?.createEditTask?.data?.template;
  const { taskTemplate, isProject, projectType } =
    dialogState?.createEditTask?.data?.dialogState || {};

  const dispatch = useDispatch();
  const { Shift } = useWorkingHours();

  //Function to reset task form
  const resetTaskForm = () => {
    setNewTaskObj(defaultTask);
    setErrors({});
    setShowLess(true);
    setShowRepeatTask(false);
    setAttachments([]);
    setAttachChecklistOpen(false);
  };
  const setPreSelectedTaskTime = () => {
    const preFilledTaskTime = dialogState?.createEditTask?.data?.taskTime;
    const selectedStartTime = preFilledTaskTime
      ? preFilledTaskTime?.startTime
      : '';
    const selectedDueTime = preFilledTaskTime ? preFilledTaskTime?.dueTime : '';
    setTaskTime({ startTime: selectedStartTime, dueTime: selectedDueTime });
    //Enable time edit.tsx in repeat schedule view if time is already selected
  };

  const translateRecurType = (task: any) => {
    let requestThisTask = task.requestThisTask;
    if (
      task.requestThisTask === 'Weekly' &&
      task.intervalWeek.length === 7 &&
      task.recurringByEvery === 'Week1'
    ) {
      requestThisTask = 'Daily';
    }
    const translations = {
      Weekly: 'weekly',
      Monthly: 'monthly',
      Daily: 'daily',
      OneOff: 'oneOff',
    };
    return translations[requestThisTask];
  };

  const setPreSelectedTaskTimeForTemplate = () => {
    const preFilledTaskData = dialogState?.createEditTask?.data?.taskData;
    const preFilledTaskTime = dialogState?.createEditTask?.data?.taskTime;
    const selectedStartTime = preFilledTaskTime
      ? preFilledTaskTime?.startTime
      : getUtcToTzTime(preFilledTaskData?.startTime);
    const selectedDueTime = preFilledTaskTime
      ? preFilledTaskTime?.dueTime
      : getUtcToTzTime(preFilledTaskData?.dueTime);
    setTaskTime({ startTime: selectedStartTime, dueTime: selectedDueTime });
    setRecurType(translateRecurType(preFilledTaskData));
  };

  //Reset task form on unmount
  useEffect(() => {
    const preFilledTaskData = dialogState?.createEditTask?.data?.taskData;
    const showLessState =
      dialogState?.createEditTask?.data?.dialogState?.viewLess;
    const isRepeatTaskVisible =
      dialogState?.createEditTask?.data?.dialogState?.showRepeatTask;
    setShowLess(showLessState != false);

    if (taskTemplate?.isPrefill) {
      if (preFilledTaskData) {
        setPreSelectedTaskTimeForTemplate();
      }

      if (preFilledTaskData?.requestThisTask || isRepeatTaskVisible) {
        setShowRepeatTask(true);
      }

      preFilledTaskData &&
        updateTask({
          ...preFilledTaskData,
          ...(!preFilledTaskData?.requestThisTask && {
            requestThisTask: false,
          }),
        });
    } else {
      setPreSelectedTaskTime();
      setShowRepeatTask(isRepeatTaskVisible);

      preFilledTaskData && updateTask(preFilledTaskData);
      if (isProject) {
        setRecurType('weekly');
      }

      if (projectType) {
        setProjectTab(projectType);
      }
    }

    return () => {
      resetTaskForm();
    };
  }, []);

  const getStartDate = () => {
    const { startTime } = newTask;
    if (!startTime && !taskTime?.startTime) {
      return null;
    }
    const formatTime = taskTime.startTime
      ? moment(taskTime.startTime, 'HH:mm A').format('HH:mm')
      : moment(Shift?.startTime, 'HH:mm A').format('HH:mm');
    const startDateFormat = moment(startTime).format('MMM DD YYYY');
    let dateAndTime;
    if (!startTime && taskTime?.startTime) {
      dateAndTime = moment(startDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    if (taskTime?.startTime && startTime) {
      dateAndTime = moment(startDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    if (startTime && !taskTime?.startTime) {
      dateAndTime = moment(startDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    //In recurring task when only time is selected we force to add date along with the time because backend accepts time along with a date
    if (!startTime && taskTime?.startTime) {
      const timeWithDummyDate = moment(taskTime.startTime, 'HH:mm A').format(
        'YYYY-MM-DDTHH:mm:ss',
      );
      return getDateInTz?.(timeWithDummyDate)?.format();
    }
    return dateAndTime ? getDateInUtc?.(dateAndTime) : null;
  };

  const getDueDate = () => {
    const { dueTime } = newTask;
    if (!dueTime && !taskTime?.dueTime) {
      return null;
    }
    //taskTime only works with the start and due Time selected by user, not date
    const formatTime = taskTime?.dueTime
      ? moment(taskTime.dueTime, 'HH:mm A').format('HH:mm')
      : moment(Shift?.endTime, 'HH:mm A').format('HH:mm');
    const dueDateFormat = dueTime ? moment(dueTime)?.format('MMM DD YYYY') : '';
    let dateAndTime;
    if (!dueTime && taskTime?.dueTime) {
      dateAndTime = moment(dueDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    if (taskTime?.dueTime && dueTime) {
      dateAndTime = moment(dueDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    if (dueTime && !taskTime?.dueTime) {
      dateAndTime = moment(dueDateFormat + ' ' + formatTime).format(
        'YYYY-MM-DDTHH:mm:ss',
      );
    }
    const taskDueDate = dateAndTime && getDateInUtc?.(dateAndTime);

    //In recurring task when only time is selected we force to add date along with the time because backend accepts time along with a date
    if (!dueTime && taskTime?.dueTime) {
      const timeWithDummyDate = moment(taskTime.dueTime, 'HH:mm A').format(
        'YYYY-MM-DDTHH:mm:ss',
      );
      return getDateInTz?.(timeWithDummyDate)?.format();
    }
    if (!dueTime) return null;
    return taskDueDate;
  };

  const scheduleRecurrTasks = (scheduledDateTime, startTime, dueTime) => {
    let scheduledDate = moment(scheduledDateTime);
    if (isProject) {
      scheduledDate = moment.tz(scheduledDateTime, tz);
    }
    let newStartTime: any = null;
    let newDueTime: any = null;
    if (startTime) {
      newStartTime = getUtcToTz(startTime)?.set({
        year: scheduledDate?.year(),
        month: scheduledDate?.month(),
        date: scheduledDate?.date(),
      });
    }

    if (dueTime) {
      newDueTime = getUtcToTz(dueTime)?.set({
        year: scheduledDate?.year(),
        month: scheduledDate?.month(),
        date: scheduledDate?.date(),
      });
    }

    return {
      startTime: newStartTime?.format() || null,
      dueTime: newDueTime?.format() || null,
    };
  };

  const filterOneOffAsSimpleTask = (newTask: any) => {
    const isRecurring = recurType !== 'oneOff';
    const {
      editInstance,
      instanceCadence,
      intervalWeek,
      recurringByEvery,
      recurringTask,
      requestThisTask,
      ...rest
    } = newTask;
    if (isRecurring) {
      return newTask;
    }
    return rest;
  };

  const getSubmissionAfterDueValue = (newTask: any) => {
    if (newTask?.isTimeBound !== undefined) {
      return newTask?.isTimeBound;
    }
    if (showRepeatTask) {
      if (recurType === 'oneOff') {
        return !newTask?.dueTime;
      } else {
        return !taskTime?.dueTime;
      }
    }
    return !newTask?.dueTime;
  };

  // Create Task
  const createNewTask = async (data = {}) => {
    const {
      assignees,
      title,
      checklistId,
      AssociatedLog,
      LogItemId,
      scheduledDateTime,
      notification,
      isMultiTasks,
      isAutoTagLocation,
      assets,
      assigneesRoles,
    } = newTask;

    let dates = {
      startTime: getStartDate(),
      dueTime: getDueDate(),
    };
    //In case of show less only minimized version of task should be created, other selected values will not be considered

    if ((template ?? !showLess) && scheduledDateTime) {
      dates = scheduleRecurrTasks(
        scheduledDateTime,
        dates?.startTime,
        dates?.dueTime,
      );
    }

    const postObj =
      showLess && !template
        ? {
            ...(assignees && { assignees }),
            ...(assigneesRoles && { assigneesRoles }),
            title,
            ...(checklistId && { checklistId, isChecklistRequired }),
            ...(dialogState?.createEditTask?.data?.returnAllSchedule && {
              viewType: 'calendar',
            }),
            ...(AssociatedLog?.id && { ChecklistLogId: AssociatedLog?.id }),
            LogItemId: LogItemId || null,
            notification,
            isMultiTasks:
              (assignees?.length && isAutoTagLocation) ||
              (assignees?.length && isMultiTasks)
                ? true
                : false,
            isAutoTagLocation:
              assignees?.length && isAutoTagLocation ? true : false,
            ...(taskTemplate?.id && { TaskTemplateId: taskTemplate?.id }),
          }
        : {
            ...filterOneOffAsSimpleTask(newTask),
            ...data,
            ...dates,
            ...(dialogState?.createEditTask?.data?.returnAllSchedule && {
              viewType: 'calendar',
            }),
            ...(AssociatedLog?.id && { ChecklistLogId: AssociatedLog?.id }),
            AutomationId: automationId || null,
            scheduledDateTime: undefined,
            AssociatedLog: undefined,
            isTimeBound: getSubmissionAfterDueValue(newTask),
            notification,
            isMultiTasks:
              (assignees?.length && isAutoTagLocation) ||
              (assignees?.length && isMultiTasks)
                ? true
                : false,

            isAutoTagLocation:
              assignees?.length && isAutoTagLocation ? true : false,
            ...(taskTemplate?.id && { TaskTemplateId: taskTemplate?.id }),
            ...(isProject && {
              isProject: true,
              projectType: projectTab,
              ...(projectTab === PROJECT_TYPES.assetBasedProject && {
                assets,
              }),
            }),
          };

    const taskResponse = await createTask(postObj, {
      onClickViewWorkOrder,
    });

    if (taskResponse) {
      dialogState?.createEditTask?.data?.onSuccessCallBack?.(taskResponse);
      dispatch(
        setDialog({
          open: false,
          dialogId: 'createEditTask',
        }),
      );
    } else {
      setSubmitButtonLoading(false);
    }
  };

  //Task creation with attachments
  const createEditTaskWithAttachment = async () => {
    const response = await xeniaApi.CreateTaskAttchmentFilesApi(attachments);
    if (response) {
      const attachedList = response?.filePath;

      createNewTask({ attachment: attachedList });
    }
  };

  //Function passed to the context to update the root state of new create task
  const updateTask: any = (value) => {
    const updateObj = { ...newTask, ...value };
    setNewTask(updateObj);
  };

  //Function passed to the context to replace the new task object in state
  const setNewTaskObj: any = (value) => {
    setNewTask(value);
  };

  //Submit create form
  const handleTaskFormSubmit = () => {
    //Title field is required, show error if title field is empty
    if (!newTask.title) {
      setErrors({
        ...errors,
        title: VALIDATION_ERROR_MESSAGES.TASK_TITLE_REQUIRED,
      });
      return;
    }

    // Custom Handler
    if (actionBtn?.clickHandler) {
      const finalTaskData = !showLess
        ? { ...newTask, ...(attachments?.length > 0 && { attachments }) }
        : {
            ...(newTask?.assignees && { assignees: newTask?.assignees }),
            ...(newTask?.assigneesRoles?.roles?.length > 0 && {
              assigneesRoles: newTask?.assigneesRoles,
            }),
            title: newTask?.title,
            ...(newTask?.checklistId && { checklistId: newTask?.checklistId }),
          };

      actionBtn?.clickHandler?.(
        finalTaskData,
        dialogState?.createEditTask?.data?.taskTemplateInfo,
      );
      return;
    }

    setSubmitButtonLoading(true);
    if (attachments?.length && !showLess) {
      createEditTaskWithAttachment();
    } else {
      createNewTask();
    }
  };
  const closeAction = () => {
    dispatch(
      setDialog({
        open: false,
        dialogId: 'createEditTask',
      }),
    );
  };

  const taskData = dialogState?.createEditTask?.data?.taskData;

  const AssociatedLog = taskData?.AssociatedLog;
  const automationText = taskData?.automationText;
  const automationId = taskData?.automationId;
  const LogItemId = taskData?.LogItemId;
  const LogItemAnswer = taskData?.LogItemAnswer;

  const LogItem = AssociatedLog?.TaskChecklistItems?.find(
    (item: any) => item.id === LogItemId,
  );

  const updateWatchers = (
    watchers: string[],
    type: 'statusChange' | 'overdue',
  ) => {
    setNewTask((prevTask) => ({
      ...prevTask,
      notification: {
        ...prevTask.notification,
        [type]: {
          recipients: watchers,
        },
      },
    }));
  };

  const updateTabValueCB = (newTabValue) => {
    setProjectTab(newTabValue);
  };

  const state: any = {
    updateTask,
    newTask,
    setNewTask,
    showLess,
    setShowLess,
    showRepeatTask,
    setShowRepeatTask,
    attachments,
    setAttachments,
    errors,
    setErrors,
    attachChecklistOpen,
    setAttachChecklistOpen,
    setNewTaskObj,
    taskTime,
    setTaskTime,
    isWeekTimeEdit,
    setIsWeekTimeEdit,
    isMonthTimeEdit,
    setIsMonthTimeEdit,
    attachedChecklist,
    setAttachedChecklist,
    isChecklistRequired,
    setIsChecklistRequired,
    AssociatedLog,
    setTaskTimeChange,
    recurType,
    setRecurType,
    updateWatchers,
    isProject,
    projectTab,
    hideElements,
  };

  const noLocationAssociated = !newTask?.locationIds && newTask?.AssetId;

  const showSubmissionAfterDue = useMemo(() => {
    if (showRepeatTask) {
      if (recurType === 'oneOff') {
        return newTask?.dueTime;
      } else {
        return taskTime?.dueTime;
      }
    }
    return newTask?.dueTime;
  }, [newTask, taskTime, recurType, showRepeatTask]);

  const projectCreateDisable = useMemo(() => {
    if (isProject) {
      if (
        projectTab === PROJECT_TYPES.userBasedProject &&
        (!newTask?.title || !(newTask?.assignees?.length > 0))
      ) {
        return true;
      }

      if (
        projectTab === PROJECT_TYPES.roleBasedProject &&
        (!newTask?.title || !(newTask?.assigneesRoles?.roles?.length > 0))
      ) {
        return true;
      }

      if (
        projectTab === PROJECT_TYPES.assetBasedProject &&
        (!newTask?.title || !(newTask?.assets?.length > 0))
      ) {
        return true;
      }
    }

    return false;
  }, [newTask, projectTab, isProject]);

  const showFooterProjectInfo = useMemo(() => {
    if (
      isProject &&
      projectTab === PROJECT_TYPES.userBasedProject &&
      newTask?.assignees?.length > 0
    )
      return true;

    if (
      isProject &&
      projectTab === PROJECT_TYPES.assetBasedProject &&
      newTask?.assets?.length > 0
    )
      return true;

    if (
      isProject &&
      projectTab === PROJECT_TYPES.roleBasedProject &&
      newTask?.assigneesRoles?.roles?.length > 0
    )
      return true;

    return false;
  }, [projectTab, isProject, newTask]);

  return (
    <CreateTaskContext.Provider value={state}>
      <CustomDialog
        hideActions={true}
        footer={
          <>
            {showFooterProjectInfo && (
              <Stack
                direction={'row'}
                bgcolor={'#03A9F4'}
                gap="8px"
                alignItems={'center'}
                p="8px 10px"
                color="white"
                m="0px 35px 10px 24px"
                borderRadius="6px"
              >
                <InfoIcon />
                {projectTab === PROJECT_TYPES.userBasedProject && (
                  <Typography
                    fontSize={'13px'}
                    fontWeight={'600'}
                    color="white"
                  >
                    {`${
                      newTask?.assignees?.length
                    } separate schedule(s) will be grouped into project "${
                      newTask?.title || 'Untitled Project'
                    }"`}
                  </Typography>
                )}

                {projectTab === PROJECT_TYPES.assetBasedProject && (
                  <Typography
                    fontSize={'13px'}
                    fontWeight={'600'}
                    color="white"
                  >
                    {`${
                      newTask?.assets?.length
                    } separate schedule(s) will be grouped into project "${
                      newTask?.title || 'Untitled Project'
                    }"`}
                  </Typography>
                )}

                {projectTab === PROJECT_TYPES.roleBasedProject && (
                  <Typography
                    fontSize={'13px'}
                    fontWeight={'600'}
                    color="white"
                  >
                    {`${
                      newTask?.assigneesRoles?.roles?.length
                    } separate schedule(s) will be grouped into project "${
                      newTask?.title || 'Untitled Project'
                    }"`}
                  </Typography>
                )}
              </Stack>
            )}

            <FooterActionsStyled
              style={{
                ...(!taskTemplate?.enable && {
                  justifyContent: 'end',
                }),
              }}
            >
              {taskTemplate?.enable && (
                <TaskTemplateDropdown
                  getStartDate={getStartDate}
                  getDueDate={getDueDate}
                  returnAllSchedule={
                    dialogState?.createEditTask?.data?.returnAllSchedule
                  }
                  taskTemplate={taskTemplate}
                />
              )}

              <Stack direction="row" gap="10px">
                <VerifyFeatureAccess>
                  <CustomButton variant={'text'} onClick={closeAction}>
                    Cancel
                  </CustomButton>
                </VerifyFeatureAccess>
                <VerifyFeatureAccess>
                  <CustomButton
                    variant={'contained'}
                    onClick={handleTaskFormSubmit}
                    buttonLoader={submitButtonLoading}
                    disabled={projectCreateDisable}
                  >
                    {actionBtn?.text || 'Create'}
                  </CustomButton>
                </VerifyFeatureAccess>
              </Stack>
            </FooterActionsStyled>
          </>
        }
        header={
          <>
            {newTask?.AssociatedLog && LogItem && (
              <HeaderLogDetail
                task={newTask}
                log={LogItem}
                logAnswer={LogItemAnswer}
                automationText={automationText}
              />
            )}

            {isProject ? (
              <CreateProjectHeader
                tab={projectTab}
                updateTabValueCB={updateTabValueCB}
              />
            ) : (
              <CreateTaskHeader />
            )}
          </>
        }
        dialogProps={{
          open: dialogState?.createEditTask?.open,
          fullWidth: true,
          fullScreen: isMobileDevice,
          onClose: closeAction,
          sx: {
            '& .MuiDialog-paper': {
              maxWidth: '650px',
            },
          },
        }}
      >
        <TaskFormCnt>
          <TaskAssigneeDropdown
            hideElements={hideElements}
            updateWatchers={updateWatchers}
            projectType={isProject ? projectTab : null}
          />
          {!hideElements?.separateTasksCreation &&
            newTask?.assignees?.length >= 1 && <BulkTaskCreationSetting />}
          <div
            className={classNames({
              showLess: template ? !template : showLess,
            })}
          >
            <div
              className={classNames({
                iconBtnsDropdownCnt: true,
                showLess: template && showLess,
              })}
            >
              <TaskPriorityDropdown />
              <TaskCategoryDropdown />
              {!hideElements?.dueDate && <TaskDateTimePicker />}
            </div>
            {!hideElements?.schedule && (
              <div style={{ marginTop: '16px' }}>
                <RepeatTaskToggle
                  {...state}
                  template={template}
                  initiateSchedule={initiateSchedule}
                  setInitiateSchedule={setInitiateSchedule}
                />
                {showSubmissionAfterDue && (
                  <Stack
                    style={{ marginTop: '16px' }}
                    border="1px solid rgba(224, 224, 224, 1)"
                    borderRadius={'6px'}
                  >
                    <Stack p="12px 15px">
                      <TaskSubmissionAfterDue
                        onCheck={() =>
                          updateTask({
                            isTimeBound:
                              newTask?.isTimeBound !== undefined
                                ? !newTask?.isTimeBound
                                : true,
                          })
                        }
                        newTask={newTask}
                      />
                    </Stack>
                    <Divider />
                    <Stack p="12px 15px">
                      <OverdueTaskNotification
                        updateWatchers={(watchers) =>
                          updateWatchers(watchers, 'overdue')
                        }
                        watchers={
                          newTask?.notification?.overdue?.recipients || []
                        }
                      />
                    </Stack>
                  </Stack>
                )}
              </div>
            )}
          </div>

          <div
            className={classNames({
              showLess: showLess,
              selectBtnsDropdownCnt: true,
            })}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TaskLocationDropdown />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TaskAssetDropdown />
              </Grid>
            </Grid>
            {(newTask?.isAutoTagLocation ||
              newTask?.assigneesRoles?.roles?.length > 0 ||
              (isProject && projectTab === PROJECT_TYPES.userBasedProject)) && (
              <Grid item xs={12}>
                <AutoTagLocationContent>
                  <InfoIcon className="icon" />
                  <AutoTagLocationNote>
                    {isProject || newTask?.assigneesRoles?.roles?.length > 0
                      ? `You can't select location and asset manually as location will be auto tagged`
                      : `You can't select location and asset manually when Auto Tag
                    Location is turned on`}
                  </AutoTagLocationNote>
                </AutoTagLocationContent>
              </Grid>
            )}
          </div>
          <div
            className={classNames({
              showLess: showLess,
              iconBtnsDropdownCnt: true,
            })}
          >
            {noLocationAssociated && <AssetInfo />}
          </div>
          {newTask?.AssociatedLog && <SubmissionAttachment />}
          <AddChecklist />
          <div className={classNames({ showLess: showLess })}>
            <TaskDescriptionInput />
            <TaskAttachment
              setUploadsChange={setUploadsChange}
              preFilledFiles={newTask?.preFilledFiles}
            />
          </div>
          {!isProject && <ShowMoreLess />}
        </TaskFormCnt>
      </CustomDialog>
    </CreateTaskContext.Provider>
  );
}

export default CreateTask;
