import classNames from 'classnames';
import AddIcon from 'components/Icons/addIcon';
import CustomListItem from 'components/List/ListItem/ListItem';
import { useMemo, useState } from 'react';
import { Divider } from '@mui/material';
import {
  AssigneeDropdownContentCnt,
  SearchInputCnt,
  StyledList,
  UserStatusChip,
} from './styled';
import { find } from 'lodash';
import { userStatusColors } from 'helper/user/userStatus';
import { useDispatch, useSelector } from 'react-redux';
import { setDialog } from 'store/actions/dialogActions';
import selectors from 'store/selectors';
import {
  generateAssigneeDropdownData,
  generateTeamsDropdownData,
} from 'helper/task/dropdownData';
import TeamAvatar from 'components/Avatar/TeamAvatar/TeamAvatar.cmp';
import SearchInput from 'components/Form/TextField/SearchField.cmp';
import CustomButton from 'components/Button/CustomButton';
import { Stack, Typography, Box } from '@mui/material';

interface ListAndSearchPropTypes {
  listProps?: any;
  selectedIds: string[];
  closeCB: () => void;
  handleSingleSelect: (users: any) => void;
  width?: number;
  blacklistedIds?: string[];
  hideAddUser?: boolean;
  hideAllUserOption?: boolean;
}

const allUsersOption = {
  id: 'all',
  label: 'All users & teams',
  value: 'All users & teams',
  startIcon: <TeamAvatar width={28} height={28} teamId={'all'} />,
  endIcon: <></>,
  isTeam: true,
};

const ListAndSearch = ({
  listProps = {},
  selectedIds,
  closeCB,
  handleSingleSelect,
  width,
  blacklistedIds = [],
  hideAddUser = false,
  hideAllUserOption,
}: ListAndSearchPropTypes) => {
  const dispatch = useDispatch();
  const userList = useSelector(selectors.getUserAdmin);

  const teams = useSelector(selectors.getTeams);

  //Generating Members data for dropdown
  const membersDropdownData = useMemo(() => {
    return generateAssigneeDropdownData(userList)
      ?.filter((o) => o.id != 'unassigned')
      .filter((u) => !blacklistedIds.includes(u.id));
  }, [userList]);

  //Generating Teams data for dropdown
  const teamsDropdownData = useMemo(() => {
    return generateTeamsDropdownData(teams).filter(
      (t) => !blacklistedIds.includes(t.id),
    );
  }, [teams]);

  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTab, setSelectedTab] = useState('All');

  const StatusChip = ({ user }) => {
    const availabilityStatus = getAvailabilityStatus(
      user.obj.UserAvailabilityStatuses,
      user.obj?.HotelId,
    );
    return (
      <UserStatusChip
        style={{
          background:
            userStatusColors?.[availabilityStatus?.currentStatus]?.background,
        }}
      >
        {availabilityStatus?.currentStatus}
      </UserStatusChip>
    );
  };

  const filteredOptions = () => {
    const tabOptions = selectedTabOptions[selectedTab];
    if (!searchQuery) {
      return tabOptions;
    }
    const optionsList = tabOptions?.reduce((r, cv) => {
      r?.push(cv);
      return r;
    }, []);
    const optionsAfterFilter = optionsList?.filter((obj) => {
      return obj?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase());
    });
    return optionsAfterFilter;
  };

  const getAvailabilityStatus = (
    UserAvailabilityStatuses = [],
    hotelId = null,
  ) => {
    let availabilityStatus: any = find(UserAvailabilityStatuses, {
      HotelId: hotelId,
    });
    availabilityStatus = availabilityStatus || { currentStatus: 'offline' };
    return availabilityStatus;
  };

  const sortAllAssigneeData = (data) => {
    const sortedData = data.sort((value1, value2) => {
      const user1Status = getAvailabilityStatus(
        value1?.obj?.UserAvailabilityStatuses,
        value1?.obj?.HotelId,
      );
      const user2Status = getAvailabilityStatus(
        value2?.obj?.UserAvailabilityStatuses,
        value2?.obj?.HotelId,
      );
      const indexes = ['available', 'online', 'team', 'busy', 'offline'];
      const index1: any = value1.isTeam
        ? indexes.indexOf('team')
        : indexes?.indexOf(user1Status?.currentStatus);
      const index2: any = value2.isTeam
        ? indexes?.indexOf('team')
        : indexes?.indexOf(user2Status?.currentStatus);
      return index1 - index2;
    });
    return sortedData;
  };

  const sortTeams = (data) => {
    return data.sort((team1, team2) => {
      return team1?.label?.toLowerCase() - team2?.label?.toLowerCase();
    });
  };

  const selectedTabOptions = {
    All: sortAllAssigneeData([...membersDropdownData, ...teamsDropdownData]),
    People: sortAllAssigneeData(membersDropdownData),
    Teams: sortTeams(teamsDropdownData),
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    e.target.focus();
  };

  const handleSelectTab = (option) => {
    setSelectedTab(option);
  };

  const handleAddUserDialog = () => {
    dispatch(
      setDialog({
        dialogId: 'inviteUserDialog',
        open: true,
      }),
    );
    closeCB && closeCB();
  };

  const dropdownOptions = useMemo(
    () =>
      hideAllUserOption
        ? filteredOptions()
        : [allUsersOption, ...filteredOptions()],
    [hideAllUserOption, searchQuery, selectedTab],
  );

  return (
    <>
      <AssigneeDropdownContentCnt
        style={{
          width: width,
        }}
      >
        <SearchInputCnt>
          <SearchInput
            onChange={handleSearch}
            value={searchQuery}
            fieldProps={{
              placeholder: 'Search Employee or Team',
            }}
          />
        </SearchInputCnt>
        <div className="tabsCnt">
          {['All', 'People', 'Teams'].map((t) => {
            return (
              <div
                className={classNames(
                  { tab: true },
                  { selectedTab: t === selectedTab },
                )}
                onClick={() => handleSelectTab(t)}
              >
                {t}
              </div>
            );
          })}
        </div>
        <Divider light />
        <Stack
          padding={'10px 0px'}
          alignItems={'center'}
          direction={'row'}
          gap={'9px'}
        >
          <Typography
            color={'rgba(0, 0, 0, 0.6)'}
            fontSize={14}
            fontWeight={600}
          >
            {selectedTabOptions['People']?.length} Employees
          </Typography>
          <Box
            width={6}
            height={6}
            borderRadius={'100%'}
            bgcolor={'rgba(219, 226, 240, 1)'}
          />
          <Typography
            color={'rgba(0, 0, 0, 0.6)'}
            fontSize={14}
            fontWeight={600}
          >
            {selectedTabOptions['Teams']?.length} Teams
          </Typography>
        </Stack>
        <StyledList
          sx={{ width: '100%' }}
          dense={true}
          disablePadding
          {...listProps}
        >
          {dropdownOptions.map((option, i) => {
            const { endIcon, startIcon } = option;
            const endIconRenderer: any =
              option.isTeam && endIcon ? endIcon : <StatusChip user={option} />;

            return (
              <>
                <CustomListItem
                  checkbox={false}
                  styleSelected={true}
                  key={option.id}
                  id={option.id}
                  selected={selectedIds.includes(option?.id)}
                  onClick={() => handleSingleSelect(option)}
                  startIcon={startIcon}
                  endIcon={endIconRenderer}
                >
                  {option.label}
                </CustomListItem>
              </>
            );
          })}
        </StyledList>
        <Divider sx={{ marginBottom: '8px' }} />
        {!hideAddUser && (
          <CustomButton
            id={'addNewUserBtn-assignee-dropdown'}
            startIcon={<AddIcon sx={{ color: '#6868FE' }} />}
            onClick={() => {
              closeCB && closeCB();
              handleAddUserDialog();
            }}
          >
            Add New User
          </CustomButton>
        )}
      </AssigneeDropdownContentCnt>
    </>
  );
};

export default ListAndSearch;
