// React
import React, { useEffect, useMemo, useState } from 'react';

// Utilities
import { CellContentWrapper } from 'pages/task/TaskListView/taskListView.style';
import { useTaskEdit } from 'controller/useTaskEdit';
import { AssigneeIconBtn } from 'components/Dropdown/AssigneeDropdown/assigneeDropdownSelect.style';
import AddAssigneeIcon from 'components/Icons/addAssigneeIcon';
import AssigneeDropdown from 'components/Dropdown/AssigneeDropdown/dropdown/dropdown';
import AssigneesView from 'components/AssigneesView/AssigneesView';
import useWorkspaceConfig from 'utils/CustomHooks/useWorkspaceConfig';
import { useAssigneesUtils } from 'utils/CustomHooks/useAssigneesUtil';
import { isEqual } from 'lodash';
import useUsers from 'utils/CustomHooks/useUsers';
import { PERMISSIONS } from 'utils/constants';
import AssignToRoles from 'components/TaskInputs/assignTo/roles';
import { ROLES, USER_AND_TEAMS } from 'components/TaskInputs/assignTo/utils';

const AssigneeDropdownCmp = ({ task, buttonStyles = {} }) => {
  const { getBacklistUsersByPermission } = useUsers();
  const { config } = useWorkspaceConfig();
  const { handleUpdateTask } = useTaskEdit(task);
  const { splitAssigneesForTasks } = useAssigneesUtils();

  const [dropdownConfig, setDropdownConfig] = useState({
    people: true,
    teams: true,
    locations: false,
  });

  const [selected, setSelected] = useState<any>({
    ...(dropdownConfig?.people && { people: [] }),
    ...(dropdownConfig?.teams && { teams: [] }),
    ...(dropdownConfig?.locations && { locations: [] }),
  });

  const [selectedRoles, setSelectedRoles] = useState<any>([]);

  useEffect(() => {
    setDropdownConfig({
      ...dropdownConfig,
      locations: config?.advancedLocationBasedAssignment ?? false,
    });
  }, [config]);

  useEffect(() => {
    const splitedData = splitAssigneesForTasks(
      task?.TaskAssignees,
      dropdownConfig,
    );
    setSelected(splitedData);
  }, [task?.TaskAssignees, dropdownConfig]);

  const handleUpdateAssignee = async (data) => {
    const mergedArray = [
      ...(data?.selectedList?.people ?? []),
      ...(data?.selectedList?.teams ?? []),
      ...(data?.selectedList?.locations ?? []),
    ];

    setSelected(data?.selectedList);
    handleUpdateTask({ assignees: mergedArray });
  };

  const handleUpdateRoles = async (data) => {
    setSelectedRoles(data?.roles ?? []);
    handleUpdateTask({ assignees: data?.roles ?? [] });
  };

  const assigneeType = useMemo(() => {
    if (!task?.TaskAssignees?.[0]?.Role) {
      return USER_AND_TEAMS;
    } else {
      setSelectedRoles([task?.TaskAssignees?.[0]?.Role?.id]);
    }
    return ROLES;
  }, [task?.TaskAssignees]);

  return (
    <CellContentWrapper data-attribute="cell-click">
      {assigneeType === ROLES && (
        <AssignToRoles
          handleChangeCallback={handleUpdateRoles}
          currentSelected={{ roles: selectedRoles, locations: [] }}
          customParentBtnRenderer={
            <>
              {(task?.TaskAssignees?.length === 0 || !task?.TaskAssignees) && (
                <AssigneeIconBtn style={buttonStyles}>
                  <AddAssigneeIcon />
                </AssigneeIconBtn>
              )}

              {task?.TaskAssignees?.length == 1 && (
                <AssigneesView type="single" data={task?.TaskAssignees} />
              )}
            </>
          }
          rolesProps={{
            hideLocations: true,
            hideConfig: true,
          }}
          popperProps={{
            disablePortal: false,
            style: {
              zIndex: 1300,
              width: 400,
            },
          }}
        />
      )}

      {assigneeType === USER_AND_TEAMS && (
        <AssigneeDropdown
          buttonRenderer={
            <>
              {(task?.TaskAssignees?.length === 0 || !task?.TaskAssignees) && (
                <AssigneeIconBtn style={buttonStyles}>
                  <AddAssigneeIcon />
                </AssigneeIconBtn>
              )}

              {task?.TaskAssignees?.length == 1 && (
                <AssigneesView type="single" data={task?.TaskAssignees} />
              )}

              {task?.TaskAssignees?.length > 1 && (
                <AssigneesView
                  type="avatars"
                  data={task?.TaskAssignees}
                  assigneesProps={{ isMenuToShow: false }}
                />
              )}
            </>
          }
          handleChangeCallback={handleUpdateAssignee}
          popperProps={{ disablePortal: false }}
          buttonRendererProps={{
            style: {
              width: '100%',
            },
          }}
          showTabs={true}
          listProps={{
            all: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti: true,
              placeholder: 'Type to Search',
              hideActions: false,
              partition: true,
              selected: [
                ...(selected?.people ?? []),
                ...(selected?.teams ?? []),
                ...(selected?.locations ?? []),
              ],
            },
            people: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti: true,
              hideActions: false,
              partition: true,
              selected: selected?.people ?? [],
            },
            teams: {
              showInlineSelection: false,
              isSearchable: true,
              isMulti: true,
              hideActions: false,
              selected: selected?.teams ?? [],
            },
            ...(dropdownConfig?.locations && {
              locations: {
                selectOnly: true,
                showInlineSelection: true,
                isSearchable: true,
                isMulti: true,
                hideActions: false,
                selected: selected?.locations ?? [],
                addLocationByTag: true,
              },
            }),
          }}
          displayOptions={{
            all: true,
            ...dropdownConfig,
          }}
          blacklistedIds={getBacklistUsersByPermission(
            PERMISSIONS.CAN_CHANGE_TASK_STATUS,
            true,
          )}
          allSelected={false}
        />
      )}
    </CellContentWrapper>
  );
};
const arePropsEqual = (prevProps, nextProps) => isEqual(prevProps, nextProps);

export const MemoizedAssigneeDropdownCmp = React.memo(
  AssigneeDropdownCmp,
  arePropsEqual,
);
