/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef } from 'react';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Tab, Tabs } from '@mui/material';
import selectors from 'store/selectors';
import actions from 'store/actions';
import { setDialog } from 'store/actions/dialogActions';
import { TASK_STATUS, DATE_FORMAT, appRoutes } from 'utils/globalVariable';
import { TaskComments } from 'components/common/jsxrender/task/TaskComments';
import TaskChecklist from 'components/TaskChecklist';
import { getTimeString, formatDate } from 'utils/globalFunction';
import TaskDetailSkeleton from 'components/common/jsxrender/task/TaskDetailSkeleton';
import { getUserHotelTz } from 'store/selectors/auth';
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import DateTime from 'utils/DateTime';
import { PreviewChecklistBody } from 'components/common/jsxrender/checklistV2/PreviewChecklistBody';
import CustomButton from 'components/Button/CustomButton';
import TaskDetailHeader from './Header';
import { useNotification } from 'components/NotificationV2/useNotification';
import appConstants from 'utils/appConstants';
import TaskActivityTimeline from 'components/TaskActivityTimeline';
import useTaskList from 'utils/CustomHooks/useTasksList';
import {
  TaskNotFoundEmptyState,
  SubmitCnt,
  ChecklistContent,
  CheckListCnt,
  NoChecklist,
  TabsCnt,
  FormCnt,
  ActionsCnt,
  ChecklistOuterCnt,
} from './TaskDetail.style';
import CTypography from 'components/CTypography';
import TaskNotFoundIcon from '@mui/icons-material/SmsFailedOutlined';
import xeniaApi from 'api/index';
import useMeasure from 'react-use-measure';
import DIALOGS from 'utils/dialogIds';
import { useTaskEdit } from 'controller/useTaskEdit';
import TaskActionDropdown from 'pages/task/TaskListView/TaskActionDropdown/TaskActionDropdown';
import { useCustomPermission } from 'utils/CustomHooks/useCustomPermission';
import Stack from '@mui/material/Stack';
import { getAutomationString } from 'xeniautils';
import useLocationsMap from 'utils/CustomHooks/useLocationsMap';
import FileIconWithLines from 'components/Icons/fileIconWithLines';
import TaskDetailTab from './TaskDetailTab';

function a11yProps(selected) {
  return {
    id: `task-detail-${selected}`,
    'aria-controls': `task-detail-${selected}`,
  };
}

interface TaskDetailPropTypes {
  taskId?: any;
  activeTab?: string;
  hideHeader?: boolean;
  successCB?: any;
  request?: boolean;
  requestHeader?: number;
  options?: any;
  handleChangeLogCb?: any;
  handleChangeFormattedLogCb?: any;
  handleSaveChecklist?: any;
  handleSetCurrentLogId?: any;
  onChecklistSubmitCb?: any;
  handleClose?: any;
}

function TaskDetail({
  taskId,
  activeTab = 'detail',
  hideHeader = false,
  successCB,
  request = false,
  requestHeader = 190,
  options = { inline: false },
  onChecklistSubmitCb = undefined,
  handleChangeLogCb,
  handleChangeFormattedLogCb,
  handleSaveChecklist,
  handleSetCurrentLogId,
  handleClose,
}: TaskDetailPropTypes) {
  const { hasPermission, PERMISSIONS } = useCustomPermission();
  const [ref, bounds] = useMeasure();
  const [progressHeight, setProgressHeight] = useState(0);
  const [filtersHeight, setFiltersHeight] = useState(0);

  const { getTasks, isCalendarView } = useTaskList();

  const { handleTaskDetailStatusChange, markAsCompleteCheck } = useTaskEdit();

  const dispatch = useDispatch();
  const { setMarkAllAsRead } = useNotification();
  const [currentTask, setCurrentTask] = useState<any>(null);
  const [triggerSubmission, setTriggerSubmission] = useState<any>(null);

  const [checkListSubmittedEvent, setCheckListSubmittedEvent] =
    useState<any>(null);

  const [loading, setLoading] = useState(true);
  const [initialTime, setInitialTime] = useState<string>('00:00:00');
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const commentChannelsHash = useSelector(selectors.getTaskCommentChannelsHash);
  const userProfileData: any = useSelector(selectors.getUserProfile);
  const taskPreviewChecklist = useSelector(selectors.getChecklistDataOnly);
  const currentTaskChecklistData = useSelector(selectors.getChecklistLog);
  const dialogState = useSelector(selectors.getDialogState);

  const prevCurrentTaskChecklistData = useRef(currentTaskChecklistData);
  const hotelId = useSelector(selectors.getUserHotelId);
  const tz = useSelector(getUserHotelTz) || 'America/Chicago';
  const { workspaceId, navigateWithWorkspaceUrl } = useWorkspaceHook();
  const [selectedTab, setSelectedTab] = useState(activeTab);
  const channelId = `task_comments_${taskId}` as any;
  const ChecklistLogItem = currentTask?.ChecklistLogItem;

  const fullScreenTemplateDialogState =
    dialogState?.[DIALOGS.FULLSCREEN_TEMPLATE_IN_PROGRESS_DIALOG];

  const automationText = getAutomationString(
    ChecklistLogItem,
    currentTask?.Automation,
  );

  const loaders = useSelector(selectors.getLoaders);

  const taskStart =
    currentTask?.startTime ||
    (currentTask?.recurringTask && currentTask?.startDate
      ? moment(currentTask?.startDate).tz(tz)
      : null);
  const taskDue =
    currentTask?.dueTime ||
    (currentTask?.recurringTask ? currentTask?.dueDate : null);

  const currentCommentChannel = commentChannelsHash?.[channelId] || null;

  const taskDetailCallBack = async (
    id?: any,
    activeLoading?: any,
    isRecur?: any,
    isArchive?: any,
  ) => {
    if (isArchive) {
      closeTaskDetail();
    } else if (!isRecur) {
      const taskData = await xeniaApi.taskDetailSingleListing({
        id: id || taskId,
      });
      setCurrentTask(taskData?.data);
    } else {
      closeTaskDetail();
    }
    // Parent Success CB
    successCB?.();
  };

  useEffect(() => {
    dispatch(actions.setChecklist(null));
    dispatch(actions.setChecklistLog(null));
  }, []);

  useEffect(() => {
    handleSetCurrentLogId?.(currentTaskChecklistData?.id);
  }, [currentTaskChecklistData]);

  useEffect(() => {
    let headerHeight = 0;
    let checklistProgress = 0;
    let checklistFilters = 0;
    let requestDialogHeader = 0;

    headerHeight = bounds.height || 0;
    checklistProgress = progressHeight || 0;
    checklistFilters = filtersHeight || 0;

    if (request) {
      requestDialogHeader = requestHeader;
    }

    setCalculatedHeight(
      headerHeight + checklistProgress + checklistFilters + requestDialogHeader,
    );
  }, [currentTaskChecklistData, bounds, progressHeight, filtersHeight]);

  const markNotificationsAsRead = async () => {
    try {
      const postData: any = {
        currentUserId: userProfileData?.id,
      };
      if (selectedTab === 'detail') {
        postData.TaskId = taskId;
      } else {
        postData.ChannelId = channelId;
      }
      await setMarkAllAsRead(postData);
    } catch (error) {
      /***/
    }
  };

  const isUpdateTaskStatusSuccess = useSelector(
    selectors.getIsUpdateTaskStatusSuccess,
  );

  useEffect(() => {
    if (taskId) {
      const payload = {
        id: taskId,
      };
      dispatch(actions.taskDetailChecklistApiCall(payload));
      firstLoad();
    }
  }, [taskId, fullScreenTemplateDialogState]);

  const firstLoad = async () => {
    setLoading(true);
    const taskData = await xeniaApi.taskDetailSingleListing({
      id: taskId,
    });
    setCurrentTask(taskData?.data);

    setLoading(false);
  };

  useEffect(() => {
    const isOpenMarkAsCompleteCheck =
      checkListSubmittedEvent === 'templateAttached' &&
      prevCurrentTaskChecklistData?.current?.status === 'In Progress' &&
      currentTaskChecklistData?.status === 'Submitted';

    if (isOpenMarkAsCompleteCheck && currentTask?.taskStatus != 'Completed') {
      markAsCompleteCheck(
        currentTask,
        'Would you like to mark this task as completed?',
        () => taskDetailCallBack(currentTask?.id),
      );
      setCheckListSubmittedEvent(null);
    }

    prevCurrentTaskChecklistData.current = currentTaskChecklistData;
  }, [currentTaskChecklistData]);

  useEffect(() => {
    if (taskId && currentTask?.ChecklistId) {
      dispatch(actions.getChecklistApiCall(currentTask?.ChecklistId));
    }
  }, [currentTask, fullScreenTemplateDialogState]);

  useEffect(() => {
    if (isUpdateTaskStatusSuccess && currentTask?.id) {
      if (isCalendarView()) {
        getTasks();
      } else {
        getTasks();
      }
      dispatch(actions.setChangeTaskStatusSuccess(false));
    }
  }, [isUpdateTaskStatusSuccess]);

  useEffect(() => {
    if (taskId) markNotificationsAsRead();
  }, [taskId, selectedTab]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (currentTask?.taskTime?.timerState === 'running') {
        getRunningTime();
      }
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [currentTask?.startedAt, currentTask?.taskTime?.timerState]);

  const formatTaskDates = (date) => {
    const fullFormat = 'MMM DD YYYY [at] hh:mm A';
    const timeOnly = '[at] hh:mm A';
    let dayString = '';

    const isToday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime().toTz(tz).toTzString(tz, DATE_FORMAT);
    const isYesterday =
      formatDate(date, DATE_FORMAT, '', tz) ===
      new DateTime()
        .toTz(tz)
        .transform({ subtract: [1, 'day'] })
        .toTzString(tz, DATE_FORMAT);

    if (isToday) {
      dayString = 'Today';
    } else if (isYesterday) {
      dayString = 'Yesterday';
    }
    return dayString.length
      ? `${dayString} ${formatDate(date, timeOnly, '', tz)}`
      : formatDate(date, fullFormat, '', tz);
  };

  const getRunningTime = () => {
    const currentTime = new DateTime();
    if (currentTask?.taskTime?.lastDate) {
      const initialTime = DateTime.fromUTCString(
        currentTask?.taskTime?.lastDate,
      );
      const ts = currentTime.raw().diff(initialTime.raw(), 'seconds');
      const durationInSeconds = currentTask?.secondsSpent
        ? ts + currentTask?.secondsSpent
        : ts;
      const { days, hours, minutes, seconds } =
        secondsToDaysHoursMinutesSeconds(durationInSeconds);
      const timerStr = getTimeString(days, hours, minutes, seconds, true);
      setInitialTime(timerStr);
    }
  };

  const secondsToDaysHoursMinutesSeconds = (sec) => {
    let days: any = '',
      hours: any = '',
      minutes: any = '',
      seconds: any = '';
    days = Math.floor(sec / (60 * 60 * 24));
    hours = Math.floor(sec / (60 * 60)) % 24;
    minutes = Math.floor(sec / 60) % 60;
    seconds = sec % 60;
    return { days, hours, minutes, seconds };
  };

  const handleStartChecklistClick = () => {
    // If on mobile app then send a message to RN WebView //
    if (globalThis?.platform === 'mobile') {
      globalThis.ReactNativeWebView?.postMessage(
        JSON.stringify({
          navigateTo: 'ChecklistFullfil',
          data: {
            checklistId: currentTask?.Checklist?.id,
            taskId: currentTask?.id,
          },
        }),
      );
      return;
    }

    dispatch(
      actions.createChecklistLog({
        checklistId: currentTask?.Checklist?.id,
        hotelId,
        postData: { taskId: currentTask?.id },
      }),
    );
    setSelectedTab('checklist');
  };

  const handleTaskStatusChangeClick = (selected) => {
    if (
      selected.label === TASK_STATUS.Completed &&
      currentTaskChecklistData &&
      currentTaskChecklistData?.status != 'Submitted'
    ) {
      markAsCompleteCheck(
        currentTask,
        'Would you like to mark this task as complete without submitting the template?',
        () => taskDetailCallBack(currentTask?.id),
      );
    } else {
      handleTaskDetailStatusChange(currentTask, selected, taskDetailCallBack);
    }
  };

  const handleNextTaskStatusChange = () => {
    const getFormattedLabel = (label) => ({
      label: label,
    });

    if (currentTask?.taskStatus === TASK_STATUS.Open) {
      handleTaskStatusChangeClick(getFormattedLabel(TASK_STATUS.InProgress));
    } else if (currentTask?.taskStatus === TASK_STATUS.InProgress) {
      handleTaskStatusChangeClick(getFormattedLabel(TASK_STATUS.OnHold));
    } else if (currentTask?.taskStatus === TASK_STATUS.OnHold) {
      handleTaskStatusChangeClick(getFormattedLabel(TASK_STATUS.Completed));
    }
  };

  const closeTaskDetail = () => {
    handleClose?.();
    dispatch(
      setDialog({
        open: false,
        dialogId: 'taskDetail',
      }),
    );
  };

  const submitChecklistLog = async () => {
    await handleSaveChecklist?.();
    dispatch(
      actions.submitChecklistLog({
        hotelId: currentTaskChecklistData.HotelId,
        logId: currentTaskChecklistData.id,
        onChecklistSubmitCb: () => {
          handleChangeFormattedLogCb(null);
          setCheckListSubmittedEvent('templateAttached');
        },
      }),
    );
  };

  // Submission Check.
  const checkListSubmitCheck = async (currLog) => {
    await handleSaveChecklist?.();
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.CHECKLIST_SUBMIT_CHECK,

        data: {
          disableSubmission: false,
          checkList: currLog,
          setCheckListSubmittedEvent,
          onChecklistSubmitCb,
          beforeSubmitCall: handleSaveChecklist,
        },
      }),
    );
  };

  const checkListStartCheck = () => {
    dispatch(
      actions.setDialog({
        open: true,
        dialogId: DIALOGS.START_TEMPLATE_CHECK,
        data: {
          handleStartChecklistClick,
        },
      }),
    );
  };

  const handleViewLocationClick = () => {
    closeTaskDetail();
    const locationId =
      currentTask?.Location?.id || currentTask?.Asset?.Location?.id;
    const parentLocationId =
      currentTask?.Location?.ParentId || currentTask?.Asset?.Location?.ParentId;
    const urlToNavigate = parentLocationId
      ? `/${parentLocationId}/sl/${locationId}?tab=0`
      : `/${locationId}?tab=0`;
    navigateWithWorkspaceUrl(appRoutes.locations + urlToNavigate);
  };

  const handleViewAssetClick = () => {
    closeTaskDetail();
    const urlToNavigate = currentTask?.Asset?.ParentId
      ? `/${currentTask?.Asset?.ParentId}/sa/${currentTask?.Asset?.id}`
      : `/${currentTask?.Asset?.id}`;
    navigateWithWorkspaceUrl(appRoutes.assets + urlToNavigate);
  };

  const handleChange = (_, newValue) => {
    if (selectedTab === 'checklist') {
      handleSaveChecklist();
    }
    setSelectedTab(newValue);
  };

  const getTaskDetailModel = () => {
    const locationsMap = useLocationsMap();
    const viewSubmissionReport = () => {
      window.open(
        `/ws/${workspaceId}/checklistlogs/${currentTask?.AssociatedLog?.id}/report`,
        '_blank',
      );
    };

    if (loading) {
      return <TaskDetailSkeleton />;
    } else if (currentTask == null && !loading) {
      return (
        <TaskNotFoundEmptyState>
          <TaskNotFoundIcon />
          <p>Task deleted or not found.</p>
        </TaskNotFoundEmptyState>
      );
    } else {
      return (
        <FormCnt>
          {!hideHeader && (
            <TaskDetailHeader
              currentTask={currentTask}
              formatTaskDates={formatTaskDates}
              closeTaskDetail={closeTaskDetail}
              ref={ref}
              fetchTask={taskDetailCallBack}
            />
          )}

          <TabsCnt inline={options.inline}>
            <Box>
              <Tabs
                value={selectedTab}
                onChange={handleChange}
                variant="scrollable"
                allowScrollButtonsMobile
                aria-label="task detail"
              >
                <Tab
                  label="Work Order"
                  value="detail"
                  {...a11yProps('detail')}
                />

                {currentTask?.ChecklistId && (
                  <Tab
                    label="Template Attached"
                    value="checklist"
                    {...a11yProps('checklist')}
                  />
                )}

                <Tab
                  label="Comments"
                  value="comments"
                  {...a11yProps('comments')}
                />
                <Tab
                  label="Activity Log"
                  value="activity"
                  {...a11yProps('activity')}
                />
              </Tabs>

              <Stack width="100%">
                {selectedTab === 'detail' && (
                  <TaskDetailTab
                    task={currentTask}
                    options={options}
                    ChecklistLogItem={ChecklistLogItem}
                    viewSubmissionReport={viewSubmissionReport}
                    locationsMap={locationsMap}
                    automationText={automationText}
                    handleTaskStatusChangeClick={handleTaskStatusChangeClick}
                    handleViewLocationClick={handleViewLocationClick}
                    handleViewAssetClick={handleViewAssetClick}
                    taskDetailCallBack={taskDetailCallBack}
                    initialTime={initialTime}
                    handleStartChecklistClick={handleStartChecklistClick}
                    handleNextTaskStatusChangeClick={handleNextTaskStatusChange}
                  />
                )}

                {/* Checklist Section */}

                {selectedTab === 'checklist' && (
                  <ChecklistOuterCnt>
                    {!currentTaskChecklistData && taskPreviewChecklist && (
                      <ChecklistContent onClick={checkListStartCheck}>
                        <PreviewChecklistBody
                          checklist={taskPreviewChecklist}
                        />
                      </ChecklistContent>
                    )}
                    <div
                    // onClick={(e) => {
                    //   e.stopPropagation();
                    //   handleStartChecklistClick();
                    // }}
                    >
                      {currentTaskChecklistData && (
                        <CheckListCnt customHeight={calculatedHeight}>
                          <TaskChecklist
                            logId={currentTaskChecklistData?.id}
                            taskId={currentTask?.id}
                            checklistId={taskPreviewChecklist?.id}
                            isLogSubmitted={
                              currentTaskChecklistData?.status !== 'In Progress'
                            }
                            hideBottomButtons
                            triggerSubmission={triggerSubmission}
                            handleSubmit={(currLog) =>
                              currLog?.progress?.progress !== 100
                                ? checkListSubmitCheck(currLog)
                                : submitChecklistLog()
                            }
                            showFullScreenButton
                            handleChangeLog={handleChangeLogCb}
                            handleChangeFormattedData={
                              handleChangeFormattedLogCb
                            }
                            taskDetail={currentTask}
                            onChecklistLoadSuccess={() => setIsLoading(false)}
                          />
                        </CheckListCnt>
                      )}

                      {currentTaskChecklistData?.status === 'In Progress' &&
                        !isLoading && (
                          <>
                            <SubmitCnt>
                              <CustomButton
                                variant="contained"
                                sx={{ width: '100%', borderRadius: 2 }}
                                disableElevation
                                onClick={() => {
                                  setTriggerSubmission(true);
                                  setTimeout(() => {
                                    setTriggerSubmission(false);
                                  }, 500);
                                  dispatch(
                                    actions.createChecklistLog({
                                      checklistId: currentTask?.Checklist?.id,
                                      hotelId,
                                      postData: { taskId: currentTask?.id },
                                    }),
                                  );
                                }}
                              >
                                Submit
                              </CustomButton>
                            </SubmitCnt>
                          </>
                        )}
                      {currentTaskChecklistData?.status === 'Submitted' &&
                        !loaders?.checkList.isLoading && (
                          <>
                            <SubmitCnt>
                              <CustomButton
                                variant="contained"
                                sx={{
                                  width: '100%',
                                  borderRadius: 2,
                                  '& path': { stroke: '#fff' },
                                }}
                                startIcon={
                                  <FileIconWithLines sx={{ fill: 'none' }} />
                                }
                                disableElevation
                                onClick={() => {
                                  window.open(
                                    `/ws/${workspaceId}/checklistlogs/${currentTaskChecklistData?.id}/report`,
                                    '_blank',
                                  );
                                }}
                              >
                                View Report
                              </CustomButton>
                            </SubmitCnt>
                          </>
                        )}
                      {!currentTaskChecklistData && !currentTask?.Checklist && (
                        <NoChecklist>
                          <CTypography className="title">
                            No {appConstants.checklist.singular.base} Attached
                          </CTypography>
                        </NoChecklist>
                      )}
                    </div>
                  </ChecklistOuterCnt>
                )}

                {/* Comments Section */}

                {selectedTab === 'comments' && (
                  <TaskComments
                    currentTask={currentTask}
                    channelId={channelId}
                    currentCommentChannel={currentCommentChannel}
                    headerHeight={bounds.height}
                  />
                )}

                {/* Activity Logs Section */}

                {selectedTab === 'activity' && (
                  <TaskActivityTimeline currentTask={currentTask} />
                )}
              </Stack>
            </Box>

            {hideHeader &&
              currentTask?.taskNumber &&
              hasPermission(PERMISSIONS.CAN_MANAGE_TASKS) && (
                <ActionsCnt>
                  <TaskActionDropdown
                    task={currentTask}
                    successCB={taskDetailCallBack}
                  />
                </ActionsCnt>
              )}
          </TabsCnt>
        </FormCnt>
      );
    }
  };

  return <>{getTaskDetailModel()} </>;
}

export default TaskDetail;
