import { FC } from 'react';
import RolesItemsContainer from 'components/AllDialogs/RolesItemsSelectDropdown/RolesItemsContainer';

const RolesListContent: FC<any> = ({
  data,
  listProps = {},
  changeHandler,
  searchQueryCallback,
  selected,
}) => {
  const customListProps = { ...listProps };

  const selectRoleHandler = (selectedRole) => {
    changeHandler(selectedRole);
  };

  return (
    <RolesItemsContainer
      data={data}
      selected={selected}
      changeHandler={selectRoleHandler}
      contentProps={customListProps}
      searchQueryCallback={searchQueryCallback}
    />
  );
};

export default RolesListContent;
