// React
import { Fragment, useMemo } from 'react';

// React Router
import { useLocation } from 'react-router-dom';

// Custom components
import SubItem from './SubItem';

// Icons
import DashboardsIcon from 'components/Icons/sidebarIcons/dashboardsIcon';
import CustomDashboardsIcon from 'components/Icons/sidebarIcons/customDashboardsIcon';
import TablesIcon from 'components/Icons/tablesIcon';

// Hooks
import { useWorkspaceHook } from 'utils/CustomHooks/useWorkspaceHook';
import { useSelector } from 'react-redux';

// Utilities
import selectors from 'store/selectors';
import { PERMISSIONS } from 'utils/constants';
import { HasPermission } from 'components/HasPermission';

interface SubItemsListPropTypes {
  onClickItemCallback?: () => void;
}

const SubItemsList = ({ onClickItemCallback }: SubItemsListPropTypes) => {
  const currentPath = useLocation().pathname;
  const loaders = useSelector(selectors.getLoaders);
  const { navigateWithWorkspaceUrl } = useWorkspaceHook();

  const activeItem = useMemo(() => {
    if (currentPath.includes('/custom-dashboards')) {
      return 'custom-dashboards';
    }
    if (currentPath.includes('/templates-table-view')) {
      return 'template-exports';
    }
    if (currentPath.includes('/dashboards')) {
      return 'dashboards';
    } else {
      return '';
    }
  }, [currentPath]);

  const handleClickSubItem = (
    itemType: 'dashboards' | 'custom-dashboards' | 'template-exports',
  ) => {
    if (loaders?.tasksList?.isLoading) return;
    switch (itemType) {
      case 'dashboards':
        navigateWithWorkspaceUrl('/dashboards');
        break;

      case 'custom-dashboards':
        navigateWithWorkspaceUrl('/custom-dashboards');
        break;

      case 'template-exports':
        navigateWithWorkspaceUrl('/templates-table-view');
        break;

      default:
        break;
    }
    onClickItemCallback?.();
  };

  return (
    <Fragment>
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_DASHBOARDS]}>
        <SubItem
          title="Dashboards"
          isSelected={activeItem === 'dashboards'}
          icon={<DashboardsIcon filled={activeItem === 'dashboards'} />}
          onClick={() => handleClickSubItem('dashboards')}
        />
      </HasPermission>

      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_DASHBOARDS]}>
        <SubItem
          title="Custom Dashboards"
          isSelected={activeItem === 'custom-dashboards'}
          icon={
            <CustomDashboardsIcon filled={activeItem === 'custom-dashboards'} />
          }
          onClick={() => handleClickSubItem('custom-dashboards')}
        />
      </HasPermission>
      <HasPermission permissions={[PERMISSIONS.CAN_MANAGE_LOG_SUBMISSION]}>
        <SubItem
          title="Template Exports"
          isSelected={activeItem === 'template-exports'}
          icon={<TablesIcon filled={activeItem === 'template-exports'} />}
          onClick={() => handleClickSubItem('template-exports')}
        />
      </HasPermission>
    </Fragment>
  );
};

export default SubItemsList;
